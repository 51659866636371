import { Paper, Stack, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetListAddressPatient } from 'src/api/hooks/patient.query'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import Image from 'src/components/image/Image'
import { useCartContext } from '../../context'
import { SelectAddressDialog } from './SelectAddressDialog'

export const ShippingAddress = () => {
  const { t } = useTranslation()
  const { isAuthenticated } = useAuthUserContext()
  const { order, isCartInitialized, updateOrder } = useCartContext()
  const [isOpenSelectDialog, setOpenSelectDialog] = useState(false)
  const getAddressRequest = useGetListAddressPatient()

  const addresses = getAddressRequest?.data?.data || []

  const selectedAddress = useMemo(
    () => addresses?.find((item: any) => item?.addressId === order?.addressId),
    [JSON.stringify(addresses), order?.addressId]
  )

  const handleGetAddress = async () => {
    if (!isAuthenticated || !isCartInitialized) {
      return
    }
    const res = await getAddressRequest.mutateAsync({})
    const selectedAddress = res?.data?.find(
      (item: any) => item?.addressId === order?.addressId
    )

    if (!selectedAddress) {
      const selectAddress =
        res?.data?.find((item: any) => item?.isDefault) || res?.data?.[0]

      updateOrder({
        addressId: selectAddress?.addressId || '',
        addressAccount: selectAddress?.addressAccount || '',
        accountName: selectAddress?.fullName || '',
        phoneAccount: selectAddress?.phone || '',
      })
    }
  }

  useEffect(() => {
    if (!!isAuthenticated && !!isCartInitialized) {
      handleGetAddress()
    }
  }, [isAuthenticated, isCartInitialized])

  return (
    <Paper sx={{ px: 2, py: 2 }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle2" color="#576071">
          {t('cart.shipTo')}
        </Typography>
        <Typography
          variant="subtitle2"
          color="#1A9CC6"
          sx={{ cursor: 'pointer' }}
          onClick={() => setOpenSelectDialog(true)}
        >
          {t('cart.change')}
        </Typography>
      </Stack>
      {!!selectedAddress && (
        <Stack spacing={1} mt={2}>
          <Typography variant="subtitle2" color="#29313F">
            {selectedAddress?.fullName} - {selectedAddress?.phone}
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={1}
          >
            <Image
              src="/assets/shop-online/ic-work-place.svg"
              alt=""
              sx={{ width: 18 }}
            />
            <Typography
              variant="body2"
              color="#576071"
              width="calc(100% - 24px)"
            >
              <Typography variant="subtitle2" color="#576071" component="span">
                {selectedAddress?.typeAddress === 'Home'
                  ? t('cart.homePlace')
                  : t('cart.workPlace')}
                :&nbsp;
              </Typography>
              {selectedAddress?.addressAccount}, {selectedAddress?.wardsName},{' '}
              {selectedAddress?.districtName}, {selectedAddress?.provinceName}
            </Typography>
          </Stack>
        </Stack>
      )}
      <SelectAddressDialog
        open={isOpenSelectDialog}
        onClose={() => setOpenSelectDialog(false)}
        addresses={addresses}
        handleRefreshAddressList={handleGetAddress}
      />
    </Paper>
  )
}
