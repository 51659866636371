export enum Role {
  Doctor = 'Doctor',
  Hospital = 'Hospital',
  Pharmacist = 'Pharmacist',
  Pharmacy = 'Pharmacy',
  Nursing = 'Nursing',
  Analysis = 'Analysis',
  Affiliate = 'Affiliate',
  Admin = 'Admin',
  Guest = '',
}

export enum AccountType {
  Doctor = 'Doctor',
  Hospital = 'Hospital',
  Pharmacist = 'Pharmacist',
  Pharmacy = 'Pharmacy',
  Nursing = 'Nursing',
  Analysis = 'Analysis',
  Affiliate = 'Affiliate',
  Guest = '',
}
