export const APP_URL = {
  HOME: '/',
  PAGE_404: '/404',

  AUTH_LOGIN: '/auth/login',
  AUTH_LOGIN_AFFILIATE: '/auth/login-affiliate',
  AUTH_REGISTER: '/auth/register',
  REGISTER_USER: '/register',

  ABOUT_SOPHIE: '/about-sophie',
  EXECUTIVE_BOARD: '/about-sophie',
  SOPHIE_HIRING: '/hr-and-recruitment',
  SOPHIE_HIRING_DETAIL: '/hr-and-recruitment/:id',
  ONLINE_MEDICAL_BOOKING_HOME: '/appointment-by-home',
  ONLINE_MEDICAL_BOOKING_PHONE: '/appointment-by-phone',
  ONLINE_MEDICAL_BOOKING_CLINIC: '/appointment-by-clinic',
  ONLINE_MEDICAL_BOOKING_FAST_PHONE: '/appointment-by-fast-phone',

  HEALTH_SERVICE_PHYSICAL_THERAPY: '/service-physical',
  HEALTH_SERVICE_GET_BLOOD: '/service-blood',
  HEALTH_SERVICE_BABY_BATH: '/service-baby-bath',
  HEALTH_SERVICE_HUMAN_SICK: '/service-sick',

  CONTACT_SHOPIE: '/contact-sophie',

  BLOG_HEAL_HOME: '/blog-heal',
  BLOG_HEAL_SEE_ALL_NEWS_ITEM: '/blog-heal/:name',
  BLOG_HEAL_SEARCH: '/blog-heal/search',
  BLOG_HEAL_DETAIL: '/detail-news/:id',

  POLICY_GENERAL: '/policy/general-policy',
  POLICY_SERCURITY: '/policy/security-policy',
  POLICY_PAYMENT: '/policy/payment-policy',
  POLICY_SHIP: '/policy/deliver-policy',
  POLICY_SENDBACK: '/policy/pay-back-policy',
  POLICY_PROTECT: '/policy/protect-user',
  POLICY_FORDOCTOR: '/policy/general-policy',

  LOGIN: 'auth/login',
  REGISTER: 'auth/register',

  SHOP_ONLINE: '/shop-online',
  SHOP_ONLINE_BY_CATEGORY: '/shop-online/category/:slug',
  SHOP_ONLINE_PROMOTIONS: '/shop-online/promotions',
  SHOP_ONLINE_SEARCH: '/shop-online/search',
  SHOP_ONLINE_DETAIL: '/shop-online/detail',
  SHOP_ONLINE_PRODUCT_DETAIL: '/shop-online/product/:slug',
  SHOP_ONLINE_SHOP_DETAIL: '/shop-online/shop/:slug',
  SHOP_ONLINE_CART: '/shop-online/cart',
  SHOP_ONLINE_PAY_RESULT: '/shop-online/pay-result/:id',

  USER_PROFILE: '/profile',
  USER_PROFILE_PERSONAL_INFORMATION: '/profile/personal-info',
  USER_PROFILE_ADDRESS_LIST: '/profile/address-list',
  USER_PROFILE_ADDRESS_CREATE: '/profile/address-list/new-address',
  USER_PROFILE_ADDRESS_UPDATE: '/profile/address-list/edit-address/:id',
  USER_PROFILE_ORDER_TRACKING: '/profile/order-tracking',
  USER_PROFILE_ORDER_TRACKING_DETAIL: '/profile/order-tracking/:id',
}
