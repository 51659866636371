export const REST_API_SYS = {
  POST_EXPIRED_HANDSHAKE: {
    uri: '/api/sys/checkhs',
    method: 'PART',
  },
  POST_OPERATING_SYSTEM: {
    uri: '/api/sys/operatingsystem',
    method: 'POST',
  },
  GET_IP_ADDRESS_CLIENT: {
    uri: '/api/Sys/IpClient',
    method: 'GET',
  },
  HANDSHAKE_FOR_CLIENT: {
    uri: '/api/Sys/HandshakeV2',
    method: 'PATCH',
  },
  LOGIN: {
    uri: '/api/account/AuthAccount/Login',
    method: 'POST',
  },
}

export const REST_API_ADMIN = {
  LOGIN: {
    uri: '/api/admin/authadmin/login',
    method: 'POST',
  },
  LOGIN_WITH_FACEBOOK: {
    uri: '/api/admin/authadmin/facebooksignin',
    method: 'POST',
  },
  LOGIN_WITH_GOOGLE: {
    uri: '/api/admin/authadmin/googlesignin',
    method: 'POST',
  },
  GET_PROFILE: {
    uri: '/api/admin/profileadmin/getprofile',
    method: 'GET',
  },
  UPDATE_PROFILE: {
    uri: '/api/admin/profileadmin/updateprofile',
    method: 'POST',
  },
  UPDATEIMAGE_PROFILE: {
    uri: '/api/admin/profileadmin/updateavatar',
    method: 'POST',
  },

  LOGOUT: {
    uri: '/api/admin/authadmin/logout',
    method: 'POST',
  },
  SEND_OTP: {
    uri: '/api/admin/otpadmin/sendotp',
    method: 'POST',
  },
  VERIFY_OTP: {
    uri: '/api/admin/otpadmin/verifyotp',
    method: 'POST',
  },
  RESET_PASS: {
    uri: '/api/admin/profileadmin/resetpassforall',
    method: 'POST',
  },
  CHECK_FOR_ALL: {
    uri: '/api/admin/otpadmin/checkforall',
    method: 'POST',
  },
  CHECK_AUTH_ACCOUNT: {
    uri: '/api/account/authaccount/check',
    method: 'POST',
  },
}

export const REST_API_USER = {
  LOGIN: {
    uri: '/api/account/authaccount/login',
    method: 'POST',
  },
}

export const REST_API_SHOP = {
  GET_LIST: {
    uri: '/api/admin/shopadmin/listshop',
    method: 'POST',
  },
  CREATE: {
    uri: '/api/admin/shopadmin/createshop',
    method: 'POST',
  },
  UPDATE: {
    uri: '/api/admin/shopadmin/updateshop',
    method: 'POST',
  },
  UPDATE_IMAGE: {
    uri: '/api/admin/shopadmin/updateimageshop',
    method: 'POST',
  },
  DELETE_SHOP: {
    uri: '/api/admin/shopadmin/deleteshop',
    method: 'DELETE',
  },
  GET_LIST_PHARMACIST: {
    uri: '/api/admin/shopadmin/listpharmacist',
    method: 'POST',
  },
  GET_LISTPHAMARCIST: {
    uri: '/api/admin/shopadmin/listpharmacist',
    method: 'POST',
  },
  GET_MY_SHOP: {
    uri: '/api/admin/shopadmin/getmyshop',
    method: 'POST',
  },
}

export const REST_API_PRODUCT = {
  GET_LIST: {
    uri: '/api/admin/productadmin/listproduct',
    method: 'POST',
  },
  GET_DETAIL: {
    uri: '/api/admin/productadmin/detailproduct',
    method: 'POST',
  },
  CREATE: {
    uri: '/api/admin/ProductAdmin/CreateProduct',
    method: 'PUT',
  },
  UPDATE: {
    uri: '/api/admin/ProductAdmin/UpdateProduct',
    method: 'POST',
  },
  UPDATE_IMAGE: {
    uri: '/api/admin/ProductAdmin/UpdateImageProduct',
    method: 'DELETE',
  },
  DELETE_IMAGE: {
    uri: '/api/admin/ProductAdmin/DeleteImageProduct',
    method: 'POST',
  },
  DELETE_SHOP: {
    uri: '/api/admin/productadmin/deleteproduct',
    method: 'DELETE',
  },
}

export const REST_API_APPROVE = {
  GET_LIST: {
    uri: '/api/admin/approveadmin/listapprove',
    method: 'POST',
  },
  GET_USER_DETAIL: {
    uri: '/api/admin/approveadmin/detail',
    method: 'POST',
  },
  APPROVE_USER: {
    uri: '/api/admin/approveadmin/approve',
    method: 'POST',
  },
  DELETE_APPROVE: {
    uri: '/api/admin/approveadmin/remove',
    method: 'POST',
  },
}

export const REST_API_ORDER = {
  GET_LIST: {
    uri: '/api/pharmacist/servicespharmacist/listorder',
    method: 'POST',
  },
  GET_DETAIL: {
    uri: '/api/pharmacist/servicespharmacist/detailorder',
    method: 'POST',
  },
  GET_SUMMARY: {
    uri: '/api/pharmacist/servicespharmacist/summaryorder',
    method: 'POST',
  },
  UPDATE_DELIVERY_STATUS: {
    uri: '/api/pharmacist/servicespharmacist/pushorder',
    method: 'POST',
  },
}

export const REST_API_CATEGORY = {
  GET_LIST: {
    uri: '/api/admin/shopadmin/listcategory',
    method: 'GET',
  },
  GET_DETAIL: {
    uri: '/api/admin/shopadmin/detailcategory',
    method: 'GET',
  },
}

export const REST_API_DISCOUNT_PROMOTION = {
  GET_LIST: {
    uri: '/api/admin/promotionadmin/listpromotion',
    method: 'POST',
  },
  GET_DETAIL: {
    uri: '/api/admin/promotionadmin/detailpromotion',
    method: 'POST',
  },
  CREATE: {
    uri: '/api/admin/PromotionAdmin/CreatePromotion',
    method: 'PUT',
  },
  UPDATE: {
    uri: '/api/admin/PromotionAdmin/UpdatePromotion',
    method: 'POST',
  },
  UPDATE_IMAGE: {
    uri: '/api/admin/PromotionAdmin/UpdateImagePromotion',
    method: 'DELETE',
  },
  DELETE_IMAGE: {
    uri: '/api/admin/PromotionAdmin/DeleteImagePromotion',
    method: 'POST',
  },
  DELETE_PROMOTION: {
    uri: '/api/admin/promotionadmin/deletepromotion',
    method: 'DELETE',
  },
}

export const REST_API_TRANSPORT_PROMOTION = {
  GET_LIST: {
    uri: '/api/admin/TransportPromotionAdmin/ListTransportPromotion',
    method: 'POST',
  },
  GET_DETAIL: {
    uri: '/api/admin/transportPromotionAdmin/detailTransportPromotion',
    method: 'POST',
  },
  CREATE: {
    uri: '/api/admin/TransportPromotionAdmin/CreateTransportPromotion',
    method: 'PUT',
  },
  UPDATE: {
    uri: '/api/admin/TransportPromotionAdmin/UpdateTransportPromotion',
    method: 'POST',
  },
  DELETE: {
    uri: '/api/admin/transportPromotionAdmin/DeleteTransportPromotion',
    method: 'DELETE',
  },
}

export const REST_API_AUTH_DOCTOR = {
  REGISTER: {
    uri: '/api/doctor/authDoctor/register',
    method: 'POST',
  },
  CHECK_PHONE_NUMBER: {
    uri: '/api/doctor/authDoctor/check',
    method: 'POST',
  },
}

export const REST_API_PROFILE_DOCTOR = {
  UPDATE_CERTIFICATE: {
    uri: '/api/doctor/profileDoctor/updateCertificate',
    method: 'POST',
  },
  GET_LIST_TYPE_SPECIALIST: {
    uri: '/api/doctor/profiledoctor/listtypespecialist',
    method: 'POST',
  },
}

export const REST_API_AUTH_HOSPITAL = {
  REGISTER: {
    uri: '/api/hospital/authHospital/register',
    method: 'POST',
  },
}

export const REST_API_PROFILE_HOSPITAL = {
  UPDATE_CERTIFICATE: {
    uri: '/api/hospital/profileHospital/updateCertificate',
    method: 'POST',
  },
}

export const REST_API_AUTH_PHARMACIST = {
  REGISTER: {
    uri: '/api/pharmacist/authPharmacist/register',
    method: 'POST',
  },
}
export const REST_API_AUTH_ANALYSIS = {
  REGISTER: {
    uri: '/api/analysis/authanalysis/register',
    method: 'POST',
  },
}

export const REST_API_PROFILE_PHARMACIST = {
  UPDATE_CERTIFICATE: {
    uri: '/api/pharmacist/profilePharmacist/updateCertificate',
    method: 'POST',
  },
}
export const REST_API_PROFILE_ANALYSIS = {
  UPDATE_CERTIFICATE: {
    uri: '/api/analysis/profileAnalysis/updateCertificate',
    method: 'POST',
  },
}

export const REST_API_AUTH_NURSING = {
  REGISTER: {
    uri: '/api/nursing/authNursing/register',
    method: 'POST',
  },
}

export const REST_API_PROFILE_NURSING = {
  UPDATE_CERTIFICATE: {
    uri: '/api/nursing/profileNursing/updateCertificate',
    method: 'POST',
  },
}

export const REST_API_AFFILIATE_PRODUCT = {
  GET_LIST: {
    uri: '/api/affiliate/seoaffiliate/listproductseo',
    method: 'POST',
  },
  GET_DETAIL: {
    uri: '/api/affiliate/seoaffiliate/detailproductseo',
    method: 'POST',
  },
  GET_LIST_HISTORY_SEO: {
    uri: '/api/affiliate/seoaffiliate/seohistory',
    method: 'POST',
  },
  CREATE_REQUEST_WITHDRAW_POINT: {
    uri: '/api/affiliate/seoaffiliate/requestwithdrawpoint',
    method: 'POST',
  },
  GET_LIST_REQUEST_WITHDRAW_POINT: {
    uri: '/api/affiliate/seoaffiliate/listwithdrawpoint',
    method: 'POST',
  },
  SEO_SUMMARY: {
    uri: '/api/affiliate/seoaffiliate/seosummary',
    method: 'POST',
  },
}

/**
 * =================================================== USER =============================================================
 */

export const REST_DASHBOARD_SYS = {
  POST_LISTAPPOINTMENT: {
    uri: '/api/Sys/OperatingSystem',
    method: 'POST',
  },
  GET_TOP_PARTNER: {
    uri: '/api/guest/dashboardguest/topspartner',
    method: 'POST',
  },
  GET_LIST_NEWS: {
    uri: '/api/guest/DashboardGuest/ListNews',
    method: 'POST',
  },
  GET_DETAIL_NEWS: {
    uri: '/api/guest/DashboardGuest/DetailNews',
    method: 'POST',
  },
  GET_LIST_TOP_NEWS: {
    uri: '/api/guest/DashboardGuest/ListTopNews',
    method: 'GET',
  },
  GET_LIST_CATEGORY_NEWS: {
    uri: '/api/guest/DashboardGuest/ListCategoryNews',
    method: 'GET',
  },
  GET_LIST_PROMOTION: {
    uri: '/api/guest/DashboardGuest/ListPromotion?skip=0&limit=4',
    method: 'POST',
  },
  GET_LIST_PROMOTION_HOMEPAGE: {
    uri: '/api/patient/shoppatient/listpromotion?skip=0&limit=4',
    method: 'POST',
  },
  GET_LIST_PRODUCT_SALE_OFF: {
    uri: '/api/guest/DashboardGuest/ListProductSaleOff',
    method: 'POST',
  },
  GET_LIST_DASHBOARD_GUEST: {
    uri: '/api/guest/DashboardGuest',
    method: 'POST',
  },
  GET_LIST_CATEGORY: {
    uri: '/api/guest/DashboardGuest/ListCategory',
    method: 'GET',
  },
  GET_SEARCH_NEWS: {
    uri: '/api/guest/DashboardGuest/SearchNews',
    method: 'POST',
  },
}
