import { createContext, useCallback, useEffect, useRef, useState } from 'react'
import { StorageService } from 'src/api'
import {
  useCreateOrUpdateOrderPatient,
  useGetListAddressPatient,
  useGetOrderByAccountIdPatient,
  useValidatePromotionPatient,
} from 'src/api/hooks/patient.query'
import { useAuthUserContext } from 'src/auth/AuthUserContext'
import useDebounce from 'src/hooks/useDebounce'
import {
  ICartData,
  IOrderCart,
  IProduct,
  ITransportServiceData,
} from '../types/order'
import { BroadcastChannel } from 'broadcast-channel'
import { logDev, logJson } from 'src/utils'
import {
  getAffiliateIdFromUrl,
  getCurrentUrl,
  isAffiliateExpired,
  shouldUpdateProductAffiliate,
  updateProductWithAffiliate,
  clearProductAffiliate,
} from '../../common'

interface ICartContext {
  order: IOrderCart
  cartData: ICartData
  transportServiceData: ITransportServiceData
  isCartInitialized: boolean
  isOrderLoading: boolean
  updateOrder: (newValue: Partial<IOrderCart>) => void
  updateCartData: (newValue: Partial<ICartData>) => void
  updateTransportServiceData: (newValue: Partial<ITransportServiceData>) => void
  addToCart: (product: IProduct) => void
  addManyToCart: (products: IProduct[]) => void
  increaseProductAmountById: (productId: string, shopId: string) => void
  decreaseProductAmountById: (productId: string, shopId: string) => void
  setProductAmountById: (productId: string, amount: any) => void
  removeProductById: (productId: string, shopId: string) => void
  refreshOrder: () => void
}

const initialOrder: IOrderCart = {
  draftOrderId: '',
  transactionId: '',
  pharmacistId: '',
  accountId: '',
  accountName: '',
  phoneAccount: '',
  addressId: '',
  addressAccount: '',
  listProduct: [],
  listOrder: [],
  promotionIds: [],
  transportPromotionIds: [],
  price: 0,
  promotionPrice: 0,
  transportPromotionPrice: 0,
  transportPrice: 0,
  typePay: 'COD',
  transportService: '',
}

const initialCartData: ICartData = {
  typePay: 'COD',
}

const initialTransportServiceData: ITransportServiceData = {
  servicE_CODE: '',
  servicE_NAME: '',
  servicE_PRICE: 0,
  servicE_TIME: '',
}

const initialState: ICartContext = {
  order: initialOrder,
  cartData: initialCartData,
  transportServiceData: initialTransportServiceData,
  isCartInitialized: false,
  isOrderLoading: false,
  updateOrder: () => {},
  updateCartData: () => {},
  updateTransportServiceData: () => {},
  addToCart: () => {},
  addManyToCart: () => {},
  increaseProductAmountById: () => {},
  decreaseProductAmountById: () => {},
  setProductAmountById: () => {},
  removeProductById: () => {},
  refreshOrder: () => {},
}

const CartContext = createContext<ICartContext>(initialState)

function CartContextProvider({ children }: { children: JSX.Element }) {
  const { profile, isAuthenticated } = useAuthUserContext()
  const [order, setOrder] = useState(initialOrder)
  const [cartData, setCartData] = useState(initialCartData)
  const [transportServiceData, setTransportServiceData] = useState(
    initialTransportServiceData
  )
  const canUpdateCart = useRef(false)
  const getCartRequest = useGetOrderByAccountIdPatient()
  const createOrUpdateOrderRequest = useCreateOrUpdateOrderPatient()
  const orderDebounce = useDebounce(JSON.stringify(order), 500)
  const validatePromotionRequest = useValidatePromotionPatient()

  const isCartInitialized = !!order?.draftOrderId

  /**
   * Check and clean expired affiliate information in products
   * @param products - List of products to check
   * @returns Updated list of products with expired affiliates cleared
   */
  const cleanExpiredAffiliates = (products: IProduct[]): IProduct[] => {
    if (!products?.length) return products

    return products.map(product => {
      if (product.affiliateId && product.affiliateAt) {
        if (isAffiliateExpired(product.affiliateAt)) {
          logDev('Affiliate expired for product:', product.productId)
          return clearProductAffiliate(product)
        }
      }
      return product
    })
  }

  /**
   * Prepare order data before sending to server
   * @param orderData - Order data to prepare
   * @returns Prepared order data with cleaned affiliates
   */
  const prepareOrderData = (orderData: IOrderCart): IOrderCart => {
    // Clean expired affiliates in main product list
    const cleanedProducts = cleanExpiredAffiliates(orderData.listProduct || [])

    // Clean expired affiliates in each shop's order list
    const cleanedOrders =
      orderData.listOrder?.map(shopOrder => ({
        ...shopOrder,
        listProduct: cleanExpiredAffiliates(shopOrder.listProduct || []),
      })) || []

    return {
      ...orderData,
      listProduct: cleanedProducts,
      listOrder: cleanedOrders,
    }
  }

  const handleUpdateOrder = useCallback(
    async (newValue: Partial<IOrderCart>) => {
      canUpdateCart.current = false
      const newOrder = Object.assign(order, newValue)
      // Clean expired affiliates before updating
      const preparedOrder = prepareOrderData(newOrder)
      setOrder(preparedOrder)
      await handleCreateOrUpdateOrder(preparedOrder)
    },
    [order, isAuthenticated]
  )

  const handleUpdateCartData = useCallback(
    async (newValue: Partial<ICartData>) => {
      const newCartData = Object.assign(cartData, newValue)
      StorageService.set('cartData', JSON.stringify(newCartData))
      setCartData(newCartData)
    },
    [cartData, isAuthenticated]
  )

  const handleUpdateTransportServiceData = useCallback(
    async (newValue: Partial<ITransportServiceData>) => {
      const newTransportServiceData = Object.assign(
        transportServiceData,
        newValue
      )
      StorageService.set(
        'transportServiceData',
        JSON.stringify(newTransportServiceData)
      )
      setTransportServiceData(newTransportServiceData)
    },
    [transportServiceData, isAuthenticated]
  )

  const handleAddProductToCart = useCallback(
    (product: IProduct) => {
      canUpdateCart.current = true

      // Check for affiliate ID in current URL
      const currentUrl = getCurrentUrl()
      const affiliateId = getAffiliateIdFromUrl(currentUrl)

      const productExistInCart = order.listProduct?.find(
        item => item?.productId === product?.productId
      )
      if (!!productExistInCart) {
        setOrder(order => ({
          ...order,
          listProduct: order?.listProduct?.map(item => {
            if (item?.productId === product?.productId) {
              const updatedItem = {
                ...item,
                quantity: item.quantity + product.quantity,
              }

              // Update affiliate info if needed
              if (
                affiliateId &&
                shouldUpdateProductAffiliate(
                  item.productId,
                  item.affiliateId,
                  affiliateId
                )
              ) {
                return updateProductWithAffiliate(updatedItem, affiliateId)
              }

              return updatedItem
            }
            return item
          }),
        }))
      } else {
        // For new products, always check and add affiliate info
        const newProduct = affiliateId
          ? updateProductWithAffiliate(product, affiliateId)
          : product
        logJson('newProduct ', newProduct)
        setOrder(order => ({
          ...order,
          listProduct: [...order?.listProduct, newProduct],
        }))
      }
    },
    [order, isAuthenticated]
  )

  const handleAddProductsToCart = useCallback(
    (products: IProduct[]) => {
      canUpdateCart.current = true

      // Check for affiliate ID in current URL
      const currentUrl = getCurrentUrl()
      const affiliateId = getAffiliateIdFromUrl(currentUrl)

      const listProduct = [...order.listProduct]

      for (const product of products) {
        const indexInCart = listProduct.findIndex(
          item => item?.productId === product?.productId
        )

        if (indexInCart === -1) {
          // New product - add with affiliate info if available
          const newProduct = affiliateId
            ? updateProductWithAffiliate(product, affiliateId)
            : product

          listProduct.push(newProduct)
        } else {
          // Existing product - update quantity and check affiliate
          const existingProduct = listProduct[indexInCart]
          let updatedProduct = {
            ...existingProduct,
            quantity: existingProduct.quantity + (product?.quantity || 0),
          }

          // Update affiliate info if needed
          if (
            affiliateId &&
            shouldUpdateProductAffiliate(
              existingProduct.productId,
              existingProduct.affiliateId,
              affiliateId
            )
          ) {
            updatedProduct = updateProductWithAffiliate(
              updatedProduct,
              affiliateId
            )
          }

          listProduct[indexInCart] = updatedProduct
        }
      }

      setOrder(order => ({
        ...order,
        listProduct: listProduct,
      }))
    },
    [order, isAuthenticated]
  )

  const increaseProductAmountById = useCallback(
    (productId: String, shopId: String) => {
      canUpdateCart.current = true
      setOrder({
        ...order,
        listOrder: order?.listOrder?.map(itemOrder => {
          if (itemOrder?.shopId === shopId) {
            itemOrder?.listProduct?.map(itemProduct => {
              if (itemProduct?.productId === productId) {
                itemProduct.quantity = itemProduct.quantity + 1
              }
            })
          }
          return itemOrder
        }),
        listProduct: order?.listProduct?.map(item => {
          if (item?.productId === productId) {
            item.quantity = item.quantity + 1
          }
          return item
        }),
      })
    },
    [order, isAuthenticated]
  )

  const decreaseProductAmountById = useCallback(
    (productId: String, shopId: String) => {
      canUpdateCart.current = true
      setOrder(order => ({
        ...order,
        listOrder: order?.listOrder?.map(itemOrder => {
          if (itemOrder?.shopId === shopId) {
            itemOrder?.listProduct?.map(itemProduct => {
              if (itemProduct?.productId === productId) {
                itemProduct.quantity = Math.max(0, itemProduct.quantity - 1)
              }
            })
          }
          return itemOrder
        }),
        listProduct: order?.listProduct?.map(item => {
          if (item?.productId === productId) {
            item.quantity = Math.max(0, item.quantity - 1)
          }
          return item
        }),
      }))
    },
    [order, isAuthenticated]
  )

  const setProductAmountById = useCallback(
    (productId: String, amount: any) => {
      if (typeof amount === 'number') {
        canUpdateCart.current = true
      }
      setOrder(order => ({
        ...order,
        listProduct: order?.listProduct?.map(item => {
          if (item?.productId === productId) {
            item.quantity = amount
          }
          return item
        }),
      }))
    },
    [order, isAuthenticated]
  )

  const removeProductById = useCallback(
    (productId: String, shopId: String) => {
      canUpdateCart.current = true
      var listTempOrder = order?.listOrder?.map(itemOrder => {
        if (itemOrder?.shopId === shopId) {
          itemOrder.listProduct = itemOrder?.listProduct?.filter(
            itemProd => itemProd?.productId !== productId
          )
        }
        return itemOrder
      })

      setOrder(order => ({
        ...order,
        transportService: '',
        transportPromotionIds: [],
        promotionIds: [],
        listOrder: listTempOrder,
        listProduct: order?.listProduct?.filter(
          item => item?.productId !== productId
        ),
      }))
    },
    [order, isAuthenticated]
  )

  useEffect(() => {
    const handleGetLocalCartData = () => {
      try {
        const cartDataRaw: string = StorageService.get('cartData')
        return JSON.parse(cartDataRaw)
      } catch (error) {
        return {}
      }
    }
    const handleGetCartData = async () => {
      if (!isAuthenticated || !profile?.accountId) {
        setOrder({ ...initialOrder })
        return
      }
      try {
        const res: any = await getCartRequest.mutateAsync(profile?.accountId)
        canUpdateCart.current = false
        const cartData = handleGetLocalCartData()
        setCartData(Object.assign({ typePay: 'COD' }, cartData))

        if (!!res?.result) {
          setOrder({ ...cartData, ...res?.data })
        } else {
          await createOrUpdateOrderRequest.mutateAsync({
            ...initialOrder,
            accountId: profile?.accountId,
            // accountName: profile?.fullname,
            typePay: 'COD',
          })
          const res2: any = await getCartRequest.mutateAsync(profile?.accountId)
          setOrder({ ...cartData, ...res2?.data })
        }
      } catch (error) {}
    }
    handleGetCartData()

    const cartChannel = new BroadcastChannel('update-cart')
    cartChannel.onmessage = msg => handleGetCartData()
  }, [profile?.accountId])

  const handleRefreshOrder = async () => {
    const refreshOrderByTime = async (timeFail = 1) => {
      if (timeFail < 1) {
        return
      }
      try {
        const accountId = profile?.accountId
        const res: any = await getCartRequest.mutateAsync(accountId)
        canUpdateCart.current = false
        if (!res?.result) {
          const initialOrderData = {
            ...initialOrder,
            accountId: profile?.accountId,
            // accountName: profile?.fullname,
            typePay: 'COD',
          }
          await createOrUpdateOrderRequest.mutateAsync(initialOrderData)
          const res2: any = await getCartRequest.mutateAsync(accountId)
          // Clean expired affiliates when refreshing order
          setOrder(prepareOrderData(res2?.data))
        } else {
          // Clean expired affiliates when refreshing order
          setOrder(prepareOrderData(res?.data))
        }
      } catch (error) {
        setTimeout(() => {
          refreshOrderByTime(timeFail - 1)
        }, 3000)
      }
    }
    refreshOrderByTime(3)
  }

  const handleCreateOrUpdateOrder = async (newOrder = order) => {
    try {
      // Prepare order data by cleaning expired affiliates
      const preparedOrder = prepareOrderData(newOrder)

      // Validate promotions if they exist
      if (
        preparedOrder?.transportPromotionIds?.length > 0 ||
        preparedOrder?.promotionIds?.length > 0
      ) {
        const validateRes = await validatePromotionRequest.mutateAsync({
          listProduct: preparedOrder.listProduct,
          typePay: preparedOrder?.typePay || cartData?.typePay,
          transportPromotionIds: preparedOrder?.transportPromotionIds || [],
          promotionIds: preparedOrder?.promotionIds || [],
        })

        if (!validateRes?.result) {
          preparedOrder.transportPromotionIds = []
          preparedOrder.promotionIds = []
        }
      }

      canUpdateCart.current = false
      const res2 = await createOrUpdateOrderRequest.mutateAsync(preparedOrder)
      setOrder(res2?.data)
    } catch (error) {
      console.error('Error in handleCreateOrUpdateOrder:', error)
    } finally {
      //handleRefreshOrder()
    }
  }

  useEffect(() => {
    if (canUpdateCart.current && !!profile?.accountId) {
      handleCreateOrUpdateOrder()
    }
  }, [orderDebounce, profile?.accountId])

  const value = {
    order,
    cartData,
    transportServiceData,
    isCartInitialized,
    isOrderLoading: createOrUpdateOrderRequest.isLoading,
    updateOrder: handleUpdateOrder,
    updateCartData: handleUpdateCartData,
    updateTransportServiceData: handleUpdateTransportServiceData,
    addToCart: handleAddProductToCart,
    addManyToCart: handleAddProductsToCart,
    increaseProductAmountById,
    decreaseProductAmountById,
    setProductAmountById,
    removeProductById,
    refreshOrder: handleRefreshOrder,
  }

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

export { CartContextProvider, CartContext }
