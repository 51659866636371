import { format } from 'date-fns'

/**
 * Utility functions for handling affiliate tracking
 */

// Configuration constants - can be adjusted as needed
export const AFFILIATE_COOKIE_NAME = 'affiliateId'
export const AFFILIATE_EXPIRY_MINUTES = 60 * 6 // Configurable expiry time in minutes (60 minutes = 1 hour)

/**
 * Formats current date to ISO string with specific format
 * @returns Formatted date string
 */
const formatCurrentDate = (): string => {
  return format(new Date(), "yyyy-MM-dd'T'HH:mm:ss") // Note: Using HH for 24-hour format
}

/**
 * Extracts affiliate ID from URL parameters
 * @param url - Current URL or search params string
 * @returns The affiliate ID or null if not found
 */
export const getAffiliateIdFromUrl = (url: string): string | null => {
  try {
    const urlObj = new URL(url)
    return urlObj.searchParams.get('affiliateId')
  } catch {
    // If URL parsing fails, try as search params string
    const searchParams = new URLSearchParams(url)
    return searchParams.get('affiliateId')
  }
}

/**
 * Checks if a product should have its affiliate info updated
 * @param productId - The product ID to check
 * @param currentAffiliateId - The product's current affiliate ID
 * @param newAffiliateId - The new affiliate ID from URL
 * @returns Boolean indicating if affiliate info should be updated
 */
export const shouldUpdateProductAffiliate = (
  productId: string,
  currentAffiliateId: string | undefined,
  newAffiliateId: string
): boolean => {
  // Update if product has no affiliate ID or if it's different
  return !currentAffiliateId || currentAffiliateId !== newAffiliateId
}

/**
 * Updates product with affiliate information
 * @param product - The product to update
 * @param affiliateId - The affiliate ID to set
 * @returns Updated product with affiliate information
 */
export const updateProductWithAffiliate = <
  T extends { affiliateId?: string; affiliateAt?: string }
>(
  product: T,
  affiliateId: string
): T => {
  return {
    ...product,
    affiliateId: affiliateId,
    affiliateAt: formatCurrentDate(),
  }
}

/**
 * Gets current URL for affiliate checking
 * @returns Current window URL or empty string if not in browser
 */
export const getCurrentUrl = (): string => {
  if (typeof window !== 'undefined') {
    return window.location.href
  }
  return ''
}

/**
 * Checks if an affiliate timestamp has expired
 * @param affiliateAt - The timestamp when the affiliate was set
 * @returns Boolean indicating if the affiliate has expired
 */
export const isAffiliateExpired = (affiliateAt: string | null): boolean => {
  if (!affiliateAt) return true

  const affiliateDate = new Date(affiliateAt)
  const currentDate = new Date()

  // Calculate the difference in minutes instead of hours
  const diffInMinutes =
    (currentDate.getTime() - affiliateDate.getTime()) / (1000 * 60)

  return diffInMinutes >= AFFILIATE_EXPIRY_MINUTES
}

/**
 * Gets remaining time for affiliate expiration
 * @param affiliateAt - The timestamp when the affiliate was set
 * @returns Remaining minutes before expiration, or 0 if already expired
 */
export const getRemainingAffiliateMinutes = (
  affiliateAt: string | null
): number => {
  if (!affiliateAt) return 0

  const affiliateDate = new Date(affiliateAt)
  const currentDate = new Date()

  const diffInMinutes =
    (currentDate.getTime() - affiliateDate.getTime()) / (1000 * 60)

  const remainingMinutes = AFFILIATE_EXPIRY_MINUTES - diffInMinutes
  return remainingMinutes > 0 ? Math.floor(remainingMinutes) : 0
}

/**
 * Clears affiliate information from a product
 * @param product - The product to clear affiliate info from
 * @returns Product with cleared affiliate information
 */
export const clearProductAffiliate = <
  T extends { affiliateId?: string; affiliateAt?: string | null }
>(
  product: T
): T => {
  return {
    ...product,
    affiliateId: '',
    affiliateAt: null,
  }
}
