import {
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
} from '@mui/icons-material'
import { Grid, IconButton } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import { useGetListBannerPatient } from 'src/api/hooks/patient.query'
import classes from './Banner.module.less'
import { getCurrentUrl } from 'src/utils'

export const Banner = () => {
  const [slickRef, setSlickRef] = useState<Slider>()
  const listBannerRequest = useGetListBannerPatient()
  const { t } = useTranslation()

  const listBanner = listBannerRequest?.data?.listBanner || []
  const bottomRight = listBannerRequest?.data?.bottomRight || null
  const topRight = listBannerRequest?.data?.topRight || null

  useEffect(() => {
    listBannerRequest.mutate({})
  }, [])

  const slickSetting: any = {
    ref: setSlickRef,
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  if (!listBanner?.length) {
    return <></>
  }

  return (
    <div className={classes['banner-container']}>
      <Grid container spacing={1} mt={2}>
        <Grid item xs={12} lg={8} gridRow={2}>
          <div className={classes['slider-container']}>
            <IconButton
              size="small"
              disableRipple
              className={classes['button-prev']}
              onClick={() => slickRef?.slickPrev()}
            >
              <ArrowBackIosNewRounded />
            </IconButton>
            <IconButton
              size="small"
              disableRipple
              className={classes['button-next']}
              onClick={() => slickRef?.slickNext()}
            >
              <ArrowForwardIosRounded />
            </IconButton>
            <Slider {...slickSetting}>
              {listBanner?.map((item: any, i: number) => (
                <Link
                  className={classes['slide-image']}
                  to={getCurrentUrl(item?.link)}
                >
                  <img src={item?.image} alt="" />
                </Link>
              ))}
            </Slider>
          </div>
        </Grid>
        <Grid item xs={12} lg={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Grid container spacing={1}>
            {topRight && (
              <Grid item xs={12} sm={6} lg={12}>
                <Link className={classes['side-image']} to={topRight?.link}>
                  <img src={topRight?.image} alt="" />
                </Link>
              </Grid>
            )}
            {bottomRight && (
              <Grid item xs={12} sm={6} lg={12}>
                <Link className={classes['side-image']} to={bottomRight?.link}>
                  <img src={bottomRight?.image} alt="" />
                </Link>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
