// routes
import {
  PATH_ADMIN,
  PATH_AFFILIATE,
  PATH_ANALYSIS,
} from '../../../routes/paths'
// components
import { Role } from 'src/pages/admin/approve/constants/enum'
import SvgColor from '../../../components/svg-color'

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
)

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  approve: icon('ic_approve'),
  order: icon('ic_order'),
  drugstore: icon('ic_drugstore'),
  promotion: icon('ic_promotion'),
  service: icon('ic_service'),
  request: icon('ic_request'),
  logout: icon('ic_logout'),
  product: icon('ic_product'),
}

const navConfig = [
  {
    subheader: 'general',
    items: [
      // {
      //   title: 'Dashboard',
      //   path: PATH_ADMIN.general.dashboard,
      //   icon: ICONS.dashboard,
      //   roles: [Role.Pharmacy, Role.Admin],
      // },
      {
        title: 'Phê duyệt',
        path: PATH_ADMIN.general.approve,
        icon: ICONS.approve,
        roles: [Role.Admin],
      },
      {
        title: 'Đơn hàng',
        path: PATH_ADMIN.general.order,
        icon: ICONS.order,
        roles: [Role.Pharmacy, Role.Admin],
      },
      {
        title: 'Nhà thuốc',
        path: PATH_ADMIN.general.pharmacy,
        icon: ICONS.drugstore,
        roles: [Role.Pharmacy, Role.Admin],
        children: [
          {
            title: 'Nhà thuốc',
            path: PATH_ADMIN.general.shop,
            roles: [Role.Admin, Role.Pharmacy],
          },
          {
            title: 'Sản phẩm',
            path: PATH_ADMIN.general.product,
            roles: [Role.Pharmacy, Role.Admin],
          },
        ],
      },
      {
        title: 'Khuyến mãi',
        path: PATH_ADMIN.general.promotion,
        icon: ICONS.promotion,
        roles: [Role.Pharmacy, Role.Admin],
        children: [
          {
            title: 'Giảm giá',
            path: PATH_ADMIN.general.discountPromotion,
            roles: [Role.Pharmacy, Role.Admin],
          },
          {
            title: 'Vận Chuyển',
            path: PATH_ADMIN.general.transportPromotion,
            roles: [Role.Pharmacy, Role.Admin],
          },
        ],
      },
      // ANALYSIS
      {
        title: 'Dashboard',
        path: PATH_ANALYSIS.app,
        icon: ICONS.dashboard,
        roles: [Role.Analysis],
      },
      {
        title: 'Dịch vụ',
        path: PATH_ANALYSIS.service,
        icon: ICONS.service,
        roles: [Role.Analysis],
      },
      {
        title: 'Yêu cầu',
        path: PATH_ANALYSIS.request,
        icon: ICONS.request,
        roles: [Role.Analysis],
      },
      // {
      //   title: 'Đăng xuất',
      //   path: PATH_ADMIN.general.logout,
      //   icon: ICONS.logout,
      // },

      // AFFILIATE
      {
        title: 'Thống kê',
        path: PATH_AFFILIATE.statistic,
        icon: ICONS.dashboard,
        roles: [Role.Affiliate],
      },
      {
        title: 'Sản phẩm',
        path: PATH_AFFILIATE.product,
        icon: ICONS.drugstore,
        roles: [Role.Affiliate],
      },
      {
        title: 'Lịch sử SEO',
        path: PATH_AFFILIATE.historySeo,
        icon: ICONS.calendar,
        roles: [Role.Affiliate],
      },
      {
        title: 'Yêu cầu rút tiền',
        path: PATH_AFFILIATE.withdrawRequest,
        icon: ICONS.request,
        roles: [Role.Affiliate],
      },
    ],
  },
]

export default navConfig
