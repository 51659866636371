import { Link as RouterLink } from 'react-router-dom'
// @mui
import { Box, Link, Stack, Typography } from '@mui/material'

import { useTheme } from '@mui/material/styles'
// auth
// routes
import { PATH_AUTH } from '../../routes/paths'
// layouts
import LoginLayout from '../../layouts/login'
//
import { useLocales } from '../../locales'
import AuthLoginForm from './AuthLoginForm'

export default function Login() {
  const theme = useTheme()
  const { translate } = useLocales()

  const isAffiliateLogin = location.pathname === '/auth/login-affiliate'

  return (
    <LoginLayout>
      <Box>
        <Stack
          spacing={2}
          sx={{
            position: 'relative',
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          <Typography
            variant="h5"
            color="#1A9CC6"
            sx={{
              margin: { xs: '32px 16px 16px 16px', md: '0 16px 8px 0' },
            }}
          >
            {isAffiliateLogin
              ? translate('loginPage.title_affiliate')
              : translate('loginPage.title')}
          </Typography>
        </Stack>
        <AuthLoginForm />
        {/* <AuthWithSocial /> */}

        {!isAffiliateLogin && (
          <Typography
            variant="subtitle1"
            color="#798395"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '24px',
              fontWeight: theme.typography.fontWeightRegular,
            }}
          >
            {translate('loginPage.you_dont_have_account')}&nbsp;
            <Link
              component={RouterLink}
              to={PATH_AUTH.register}
              variant="subtitle1"
              color="#0F8FB9"
            >
              {translate('loginPage.register_now')}
            </Link>
          </Typography>
        )}
      </Box>
    </LoginLayout>
  )
}
