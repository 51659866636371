const vi = {
  demo: {
    title: `Việt Nam`,
    introduction: `Lorem Ipsum chỉ đơn giản là văn bản giả của ngành in ấn và sắp chữ. Lorem Ipsum đã trở thành văn bản giả tiêu chuẩn của ngành kể từ những năm 1500, khi một nhà in không xác định lấy một dãy loại và xáo trộn nó để tạo thành một cuốn sách mẫu. Nó đã tồn tại không chỉ năm thế kỷ, mà còn là bước nhảy vọt trong lĩnh vực sắp chữ điện tử, về cơ bản vẫn không thay đổi. Nó được phổ biến vào những năm 1960 với việc phát hành các tờ Letraset chứa các đoạn Lorem Ipsum, và gần đây hơn là với phần mềm xuất bản trên máy tính để bàn như Aldus PageMaker bao gồm các phiên bản của Lorem Ipsum.`,
  },
  docs: {
    hi: `Chào`,
    description: `Cần giúp đỡ？\n Vui lòng xem tài liệu của chúng tôi.`,
    documentation: `tài liệu`,
  },
  header: {
    settings: 'Thông tin cá nhân',
    Logout: 'Đăng xuất',
  },
  layout: {
    logout: 'Đăng xuất',
    confirmLogout: 'Xác nhận đăng xuất',
    confirmLogoutContent: 'Bạn có muốn đăng xuất không?',
  },
  approve: 'Phê duyệt',
  transport: 'Vận chuyển',
  contentDelete: 'Bạn có muốn thực hiện xóa?',
  contentInActive: 'Bạn có muốn thực hiện từ chối?',
  Delete: 'Xóa',
  delete: 'Xóa',
  back: 'Quay lại',
  confirm: 'Xác nhận',
  expired: 'Hết hạn',
  restore: 'Khôi phục',
  send: 'Gửi',
  Login: 'Đăng nhập',
  Register: 'Đăng xuất',
  avatar: 'Ảnh đại diện',
  id: 'STT',
  type: 'Loại',
  auth: 'Xác thực',
  name: 'Tên',
  specialist: 'Khoa',
  Pending: 'Đang chờ',
  InActive: 'Từ chối',
  Analysis: 'Phòng xét nghiệm',
  Nursing: 'Điều dưỡng',
  Pharmacist: 'Dược sĩ',
  Pharmacy: 'Nhà thuốc',
  Discount: 'Khuyến mãi',
  Advisory: 'Tư vấn',
  Hospital: 'Phòng khám',
  Doctor: 'Bác sĩ',
  approveSection: {
    list: 'Danh sách phê duyệt',

    pending: 'Đang chờ',
    approve: 'Phê duyệt',
    reject: 'Từ chối',
    rejected: 'Đã từ chối',
    delete: 'Xoá',
    search: 'Tìm kiếm',
    clear: 'Dọn',

    licenseCertificate: 'Bằng cấp',
    businessCertificate: 'Giấy phép hoạt động',
    shopInformation: 'Thông tin nhà thuốc',

    confirmTitle: 'Xác nhận',
    confirmApprove: 'Bạn có muốn phê duyệt người này không?',
    confirmReject: 'Bạn có muốn từ chối phê duyệt này không?',
    confirmDelete: 'Bạn có muốn xoá phê duyệt này không?',
    confirmDeleteAll: 'Bạn có muốn xoá những phê duyệt này không?',
    alertApproveSuccess: 'Phê duyệt thành công',
    alertRejectSuccess: 'Từ chối thành công',
    alertDeleteSuccess: 'Xoá phê duyệt thành công',
    alertDeleteAllSuccess: 'Xoá các phê duyệt thành công',
  },
  accountPage: {
    firstName: 'Họ',
    lastName: 'Tên',
    nameDoctor: 'Tên bác sĩ',
    nameHospital: 'Tên bệnh viện',
    namePharmacist: 'Tên dược sĩ',
    nameNursing: 'Tên điều dưỡng',
    nameAnalysis: 'Tên phòng xét nghiệm',
    nameAffiliate: 'Tên cộng tác viên',
    race: 'Dân tộc',
    birthdate: 'Ngày sinh',
    gender: 'Giới tính',
    avatar: 'Ảnh đại diện',
    info: 'Mô tả',
    address: 'Địa chỉ',
    email: 'Email',
    phoneNumber: 'Số điện thoại',
    save: 'Lưu thay đổi',
    General: 'Thông tin cá nhân',
    changePassword: 'Thay đổi mật khẩu',
    oldPassword: 'Mật khẩu cũ',
    newPassword: 'Mật khẩu mới',
    confirmNewPassword: 'Xác nhận mật khẩu mới',
    degree: 'Học vị',
    specialist: 'Chuyên khoa',
    homePhone: 'Số điện thoại nhà',
    exp: 'Số năm kinh nghiệm',
    workPlace: 'Nơi làm việc',
    Updatesuccess: 'Cập nhật thành công',

    allowed: 'Cho phép định dạng *.jpeg, *.jpg, *.png, *.gif',
    unFomat: 'Không đúng định dạng',
  },
  orderSection: {
    list: 'Danh sách đơn hàng',
    all: 'Tất cả',
    success: 'Thành công',
    pending: 'Đang chờ',
    paySuccessed: 'Đã thanh toán',
    paymentDelivery: 'Thanh toán khi nhận hàng',

    sortBy: 'Sắp xếp theo',
    sortByCreated: 'Mới nhất',
    sortByPurchasedNumber: 'Mua nhiều nhất',
    sortByProductPrice: 'Giá sản phẩm',
    startDate: 'Ngày bắt đầu',
    endDate: 'Ngày kết thúc',
    search: 'Tìm kiếm đơn hàng',

    serial: 'STT',
    client: 'Khách hàng',
    status: 'Trạng thái đơn hàng',
    payStatus: 'Trạng thái thanh toán',
    numOfProduct: 'Số sản phẩm',
    code: 'Mã đơn hàng',
    billLadingCode: 'Mã vận đơn',
    order: 'Đơn hàng',
    createDate: 'Ngày đặt',
    shippingStatus: 'Trạng thái vận chuyển',
    deliveryAddress: 'Địa chỉ nhận hàng',

    orderInformation: 'Thông tin đơn hàng',
    information: 'Thông tin',
    receiver: 'Người nhận',
    address: 'Đ/c',
    paymentDetail: 'Chi tiết thanh toán',
    paymentMethod: 'Phương thức thanh toán',
    initialFee: 'Tạm tính',
    shippingFee: 'Phí vận chuyển',
    discount: 'Mã giảm giá',
    total: 'Tổng cộng',

    product: 'Sản phẩm',
    image: 'Hình ảnh',
    price: 'Đơn giá',
    quantity: 'Số lượng',
    intoMoney: 'Thành tiền',
    productImage: 'Hình ảnh sản phẩm',

    waitingForDelivery: 'Chờ giao hàng',
    deliveryInProgress: 'Đang giao hàng',
    delivered: 'Đã giao hàng',
    cancelDelivery: 'Huỷ giao hàng',
    updateDeliveryStatus: 'Cập nhật tình trạng vận chuyển',
    alertUpdateDeliveryStatusSuccess:
      'Cập nhật tình trạng vận chuyển thành công',
    alertDeliveryRequired: 'Phải chọn tình trạng vận chuyển trước',
  },
  shopSection: {
    list: 'Danh sách nhà thuốc',
    name: 'Tên nhà thuốc',
    namePharmacist: 'Tên dược sĩ',
    shipment: 'Giá vận chuyển',
    newshop: 'Tạo cửa hàng',
    confirmDelete: 'Bạn có muốn xoá cửa hàng này không?',
    confirmTitle: 'Xác nhận',
    alertDeleteSuccess: 'Xoá cửa hàng thành công',
    alertDeleteError: 'Xoá cửa hàng thất bại',

    alertFieldRequired: 'Trường này không được để trống',
    alertFieldIsNumber: 'Trường này phải là số',
    alertShopImageRequired: 'Ảnh cửa hàng không được để trống',
    alertCreateShopSuccess: 'Tạo cửa hàng thành công',
    alertUpdateShopSuccess: 'Cập nhật cửa hàng thành công',

    createShopTitle: 'Thêm cửa hàng',
    updateShopTitle: 'Cập nhật cửa hàng',
    shopName: 'Tên cửa hàng',
    pharmacist: 'Dược sĩ',
    description: 'Mô tả',
    shopImage: 'Hình ảnh',
    address: 'Địa chỉ',
    province: 'Tỉnh/Thành phố',
    district: 'Quận/Huyện',
    wards: 'Phường/Xã',
    transportPrice: 'Phí vận chuyển',
  },
  productSection: {
    name: 'Tên sản phẩm',
    productPrice: 'Giá sản phẩm',
    ofPharmarcy: 'Thuộc nhà thuốc',
    Created: 'Ngày tạo',
    list: 'Danh sách sản phẩm',
    newshop: 'Tạo sản phẩm',
    zero: '0',
    twohundredthousand: '200000',
    onemilions: '1000000',
    threemilions: '3000000',
    fromprice: 'Giá từ',
    toprice: 'Cho tới',
    later: 'Mới nhất',
    search: 'Tìm kiếm',
    popularproduct: 'Sản phẩm phổ biến nhất',
    descproduct: 'Giá từ cao đến thấp',
    acsproduct: 'Giá từ thấp đến cao',
    arranged: 'Sắp xếp theo',
    pharmasy: 'Nhà thuốc',
    allShop: 'Tất cả nhà thuốc',
    allCategory: 'Tất cả danh mục',
    allSubCategory: 'Tất cả danh mục con',
    optionlevelone: 'Danh mục cấp 1',
    optionleveltwo: 'Danh mục cấp 2',
    sale: 'Khuyến mãi',
    allproduct: 'Tất cả sản phẩm',
    nosale: 'Sản phẩm không có khuyến mãi',
    sales: 'Sản phẩm có khuyến mãi',
    status: 'Trạng thái',
    createProductTitle: 'Thêm sản phẩm',
    updateProductTitle: 'Cập nhật sản phẩm',
    productName: 'Tên sản phẩm',
    pharmacist: 'Dược sĩ',
    shop: 'Nhà thuốc',
    category: 'Danh mục',
    subCategory: 'Danh mục con',
    productInfo: 'Thông tin sản phẩm',
    productInfoTitle: 'Tiêu đề',
    productInfoContent: 'Nội dung',
    addProductInfo: 'Thêm thông tin sản phẩm',
    productImage: 'Hình ảnh',
    sellOver: 'Bán vượt số lượng',
    sellOverDescription: 'Có thể tạo đơn mới khi số lượng của sản phẩm về 0',
    productRealPrice: 'Giá gốc',
    productDiscounts: 'Phần trăm giảm giá',
    rating: 'Đánh giá',
    productNumber: 'Số lượng sản phẩm',
    productWeight: 'Cân nặng sản phẩm',
    productLength: 'Chiều dài sản phẩm',
    productWidth: 'Chiều rộng sản phẩm',
    productHeight: 'Chiều cao sản phẩm',
    purchasedNumber: 'Số sản phẩm bán được',
    productDetailTitle: 'Thông tin sản phẩm',
    discount: 'Giảm giá',
    inStock: 'Còn hàng',
    lowStock: 'Sắp hết',
    outStock: 'Hết hàng',
    productLeft: 'Còn lại {{quantity}} sản phẩm',
    integerRequired: 'Trường này phải nhập số nguyên',
    alertFieldRequired: 'Trường này không được để trống',
    alertFieldIsNumber: 'Trường này phải là số',
    alertFieldIsPositiveNumber: 'Giá trị không được nhỏ hơn 0',
    alertFieldNumberIsMax100: 'Giá trị không được lớn hơn 100',
    alertProductImageRequired: 'Ảnh sản phẩm không được để trống',
    alertCreateProductSuccess: 'Tạo sản phẩm thành công',
    alertUpdateProductSuccess: 'Cập nhật cửa hàng thành công',
    confirmTitle: 'Xác nhận',
    confirmDelete: 'Bạn có muốn xoá sản phẩm này không?',
    alertDeleteSuccess: 'Xoá sản phẩm thành công',
    serviceAddTransport: 'Phụ thu',
    seoEnabled: 'Thêm thuộc tính SEO sản phẩm',
    seoDescription: 'Thêm các thuộc tính để sản phẩm được SEO tốt hơn',
  },

  promotionSection: {
    discountList: 'Danh sách khuyến mại giảm giá',
    transportList: 'Danh sách khuyến mại vận chuyển',
    newPromotion: 'Khuyến mãi mới',

    name: 'Tên khuyến mãi',
    code: 'Mã',
    quantity: 'Số lượng',
    price: 'Số tiền giảm',
    paymentMethod: 'Phương thức thanh toán',
    search: 'Tìm khuyến mãi',
    unlimited: 'Không giới hạn',

    createPromotionTitle: 'Thêm Khuyến mãi',
    updatePromotionTitle: 'Sửa Khuyến mãi',

    promotionName: 'Tên khuyến mãi',
    promotionCode: 'Mã khuyến mãi',
    shop: 'Cửa hàng',
    title: 'Tiêu đề',
    content: 'Nội dung',
    banner: 'Hình ảnh',
    typeDiscount: 'Loại khuyến mãi',
    choosePromotionType: 'Chọn loại khuyến mãi',
    promotionQuantity: 'Số lượng khuyến mãi',
    byDiscount: 'Phần trăm giảm giá',
    byPrice: 'Số tiền giảm giá',
    minBudget: 'Đơn hàng tối thiểu',
    maxPriceDiscount: 'Giảm tối đa',
    allTypePayment: 'Tất cả phương thức',
    startDate: 'Ngày bắt đầu',
    endDate: 'Ngày kết thúc',

    promotionByPercent: 'Giảm theo phần trăm',
    promotionByPrice: 'Giảm theo giá',
    freeShip: 'Miễn ship',
    reduceShip: 'Giảm ship',

    confirmDelete: 'Bạn có muốn xoá khuyến mãi này không?',
    confirmTitle: 'Xác nhận',
    alertDeleteSuccess: 'Xoá khuyến mãi thành công',
    alertDeleteError: 'Xoá khuyến mãi thất bại',

    alertFieldRequired: 'Trường này không được để trống',
    alertFieldIsNumber: 'Trường này phải là số',
    alertFieldIsPositiveNumber: 'Giá trị không được nhỏ hơn 0',
    alertFieldNumberIsMax100: 'Giá trị không được lớn hơn 100',
    alertImageRequired: 'Ảnh khuyến mãi không được để trống',
    alertDiscountRequired: 'Phần trăm giảm giá không được để trống',
    alertPriceRequired: 'Số tiền giảm giá không được để trống',
    alertStartDateMustLittleThanEndDate:
      'Ngày bắt đầu phải nhỏ hơn ngày kết thúc',
    alertCreatePromotionSuccess: 'Tạo khuyến mãi thành công',
    alertUpdatePromotionSuccess: 'Cập nhật khuyến mãi thành công',
  },

  serviceSection: {
    name: 'Dịch vụ',
    list: 'Danh sách dịch vụ',
    createService: 'Tạo dịch vụ',

    actived: 'Đã kích hoạt',
    trash: 'Đã xoá',
    serviceType: 'Loại dịch vụ',
    serviceSubType: 'Trực thuộc nhóm',
    serviceGroup: 'Nhóm dịch vụ',
    serviceName: 'Tên dịch vụ',
    servicePrice: 'Giá dịch vụ',
    created: 'Ngày tạo',

    createServiceTitle: 'Tạo dịch vụ',
    updateServiceTitle: 'Sửa dịch vụ',

    confirmTitle: 'Xác nhận',
    confirmDelete: 'Bạn có muốn xoá dịch vụ này không?',
    confirmRestore: 'Bạn có muốn khôi phục dịch vụ này không?',
    alertCreateSuccess: 'Tạo dịch vụ thành công',
    alertUpdateSuccess: 'Sửa dịch vụ thành công',
    alertTrashSuccess: 'Xoá dịch vụ thành công',
    alertRestoreSuccess: 'Khôi phục dịch vụ thành công',
  },

  requestSection: {
    name: 'Yêu cầu',
    list: 'Danh sách yêu cầu',

    pending: 'Chưa hoàn thành',
    successed: 'Đã hoàn thành',
    serviceType: 'Loại dịch vụ',
    time: 'Thời gian',
    directedByDoctor: 'Chỉ định của bác sĩ',
    status: 'Trạng thái',
    created: 'Ngày tạo',

    sendResultTitle: 'Báo cáo kết quả xét nghiệm',
    resultInfoTitle: 'Thông tin kết quả xét nghiệm',

    sendRequest: 'Gửi kết quả',
    showResult: 'Xem kết quả',
    generalInfo: 'Thông tin chung',
    testType: 'Loại xét nghiệm',
    directed: 'Chỉ định',
    requestTime: 'Thời gian yêu cầu',
    submitTime: 'Thời gian nộp kết quả',
    testResult: 'Kết quả xét nghiệm',
    testResultImage: 'Hình ảnh kết quả xét nghiệm',

    alertSendResultSuccess: 'Gửi báo cáo kết quả xét nghiệm thành công',
    mustAddResultImage: 'Phải thêm hình ảnh cho yêu cầu',
  },

  userDetail: {
    liveAt: 'Sống ở',
    addressAt: 'Địa chỉ ở',
    accountInformation: 'Thông tin tài khoản',
    numOfExp: 'Năm kinh nghiệm',
    rate: 'Đánh giá',
    workplace: 'Làm việc tại',
  },
  alertSection: {
    fieldIsRequired: 'Trường này không được để trống',
    fieldIsNumber: 'Trường này phải là số',
    fieldIsDate: 'Ngày tháng không chính xác',
    fieldIsEmail: 'Trường này phải là email',
    fieldIsInteger: 'Trường này phải là số nguyên',
    fieldNumberIsMax: 'Giá trị không được lớn hơn {{number}}',
    fieldNumberIsMin: 'Giá trị không được nhỏ hơn {{number}}',
    fieldNumberInRange: 'Giá trị chỉ được trong khoảng từ {{from}} đến {{to}}',
    dateMustLargerThanNow: 'Ngày phải lớn hơn ngày hiện tại',
    imageRequired: 'Trường này không được để trống',
    passwordLengthIsMin: 'Mật khẩu tối thiểu {{number}} ký tự',
    passwordLengthIsMax: 'Mật khẩu tối đa {{number}} ký tự',
    fileSizeIsMin: 'Kích thước tệp tin không thể nhỏ hơn {{size}}',
    fileSizeIsMax: 'Kích thước tệp tin không thể lớn hơn {{size}}',
    fileMimetypeIsIncorrect: 'Tệp tin không đúng định dạng',
    fileMimetypeIsOnlyAllowed: 'Chỉ cho phép định dạng {{mimeTypes}}',
    phoneNumberWrongFormat: 'Số điện thoại chưa hợp lệ',
    cannotEnterMoreThanNumbersAfterDecimalPoint:
      'Không thể nhập quá {{number}} chữ số thập phân',
    emailIsUsedByOther: 'Email đã được sử dụng bởi người khác',
    phoneNumberIsUsedByOther: 'Số điện thoại đã được sử dụng bởi người khác',
  },
  uploadTemplate: {
    title: 'Thả hoặc chọn tệp',
    contentPrefix: 'Thả tệp ở đây hoặc',
    contentBold: 'chọn tệp',
    contentSuffix: 'từ máy của bạn',
    removeAll: 'Xoá tất cả',
    upload: 'Tải lên',
  },
  tableTemplate: {
    noData: 'Không có dữ liệu',
  },
  noData: 'Không có dữ liệu',
  dashboard: 'Bảng điều khiển',
  cancel: 'Huỷ',
  information: 'Thông tin',
  gender: 'Giới tính',
  male: 'Nam',
  female: 'Nữ',
  yes: 'Có',
  no: 'Không',
  status: 'trạng thái',
  close: 'Đóng',
  app: `ứng dụng`,
  user: `người dùng`,
  list: `danh sách`,
  edit: `Chỉnh sửa`,
  update: `Cập nhật`,
  shop: `cửa hàng`,
  blog: `blog`,
  post: `bài viết`,
  mail: `thư`,
  chat: `trò chuyện`,
  cards: `thẻ`,
  posts: `danh sách bài viết`,
  create: `tạo`,
  kanban: `bảng`,
  general: `chung`,
  banking: `ngân hàng`,
  booking: `đặt phòng`,
  profile: `hồ sơ`,
  account: `tải khoản`,
  product: `sản phẩm`,
  invoice: `hóa đơn`,
  details: `chi tiết`,
  checkout: `thanh toán`,
  calendar: `lịch`,
  analytics: `phân tích`,
  ecommerce: `thương mại điện tử`,
  management: `quản lý`,
  menu_level: `menu`,
  menu_level_2a: `menu cấp 2a`,
  menu_level_2b: `menu cấp 2b`,
  menu_level_3a: `menu cấp 3a`,
  menu_level_3b: `menu cấp 3b`,
  menu_level_4a: `menu cấp 4a`,
  menu_level_4b: `menu cấp 4b`,
  item_disabled: `mục vô hiệu`,
  item_label: `mục nhãn`,
  item_caption: `mục chú thích`,
  item_external_link: `mục liên kết ngoài`,
  description: `mô tả`,
  other_cases: `Trường hợp khác`,
  item_by_roles: `mục theo vai trò`,
  only_admin_can_see_this_item: `Chỉ có quản trị viên mới có thể nhìn thấy mục này`,
  loginPage: {
    title: `Đăng nhập tài khoản bác sĩ, nhà thuốc`,
    title_affiliate: `Đăng nhập tài khoản cộng tác viên`,
    input_phone_or_email: `Nhập số điện thoại hoặc email đăng ký`,
    phoneNumberRegister: `Số điện thoại đăng ký`,
    phone_or_email_register: `Số điện thoại hoặc email đăng ký`,
    input_password: `Nhập mật khẩu`,
    userNameRequired: `Vui lòng nhập số điện thoại hoặc email của bạn`,
    phoneNumberRequired: `Vui lòng nhập số điện thoại của bạn`,
    phoneNumberIsWrongFormat: `Số điện thoại chưa đúng định dạng`,
    passwordRequired: `Vui lòng nhập mật khẩu`,
    or_login_by: `hoặc đăng nhập bằng`,
    you_dont_have_account: `Bạn chưa có tài khoản?`,
    register_now: `Đăng ký ngay`,
    login_account: 'Đăng nhập vào tài khoản',
  },
  registerPage: {
    title: `Đăng ký tài khoản bác sĩ, nhà thuốc`,
    registerNewAccount: `Đăng ký tài khoản mới`,
    phone_register: `Số điện thoại đăng ký`,
    send_otp_via_phone: `Gửi mã đăng ký qua điện thoại`,
    you_had_account: `Bạn đã có tài khoản?`,
    login_now: `Đăng nhập ngay`,
    or_register_by: `hoặc đăng ký bằng`,
    phoneNumberRequired: `Vui lòng nhập số điện thoại của bạn`,
    inputRegisterCodeTitle: `Nhập mã đăng ký`,
    changePhoneNumber: `Thay đổi số điện thoại đăng ký?`,
    create_password: `Tạo mật khẩu`,
    passwordRequired: `Vui lòng nhập mật khẩu`,
    chooseProvince: 'Chọn tỉnh, thành phố *',
    chooseDistrict: 'Chọn quận, huyện',
    chooseWards: 'Chọn phường, xã',
    infoDoctor: 'Thông tin chuyên môn',
    infoHospital: 'Thông tin phòng khám, bệnh viện',
    infoPharmacist: 'Thông tin chuyên môn',
    infoPharmacy: 'Thông tin nhà thuốc',
    infoNursing: 'Thông tin chuyên môn',
    infoAdvisory: 'Thông tin chuyên môn',
    information_title: 'Chào bạn đến với Sohpie',
    information_sub_title:
      'Bạn vui lòng cung cấp một vài thông tin để hoàn tất đăng ký nhé.',
    completed: 'Hoàn tất',
    continue: 'Tiếp tục',

    // layout
    welcomeToSophie: 'Chào mừng bạn đến với Sophie',
    needSomeInformation:
      'Sophie cần một số thông tin của bạn để hoàn tất quá trình đăng ký.',
    selectAccountType: 'Chọn loại tài khoản',
    personalInformation: 'Thông tin cá nhân',
    propessionalInformation: 'Thông tin cá nhân',
    step: 'Bước',

    // select role
    selectTypeAccount: 'Bạn vui lòng chọn loại tài khoản đăng ký.',
    eachAccountTypeHasDifferentFunction:
      'Mỗi loại tài khoản sẽ có các chức năng khác nhau tương ứng.',
    accountDoctor: 'Tài khoản bác sĩ',
    accountDoctorDescription:
      'Các dịch vụ thăm khám qua điện thoại, và tại nhà.',
    accountHospital: 'Phòng khám, bệnh viện',
    accountHospitalDescription:
      'Các dịch vụ thăm khám qua điện thoại, tại nhà và tại phòng khám.',
    accountPharmacist: 'Tài khoản dược sĩ',
    accountPharmacistDescription:
      'Tư vấn và bán dược mỹ phẩm, dung cụ và thiết bị y tế...',
    accountPharmacy: 'Tài khoản nhà thuốc',
    accountPharmacyDescription:
      'Các dịch vụ thăm khám qua điện thoại, và tại nhà.',

    accountNursing: 'Tài khoản điều dưỡng',
    accountAnalysis: 'Tài khoản phòng xét nghiệm',
    accountAnalysisDescription: 'Thông tin mô tả phòng xét nghiệm',
    accountNursingDescription: 'Các dịch vụ sức khoẻ, chăm sóc tại nhà.',

    // intro text
    pleaseProvideYourInformation:
      'Vui lòng cung cấp một số các thông tin cá nhân theo mẫu dưới.',
    pleaseProvideYourInformationUser:
      'Bạn vui lòng cung cấp một vài thông tin để hoàn tất đăng ký nhé.',
    loginAsDoctor: 'Bạn đang đăng ký tài khoản bác sĩ.',
    loginAsDoctorDescription:
      'Các thông tin chuyên môn giúp Sophie xác thực tài khoản của bạn.',
    loginAsHospital: 'Bạn đang đăng ký tài khoản phòng khám, bệnh viện',
    loginAsHospitalDescription:
      'Vui lòng cung các thông tin về phòng khám giúp Sophie xác thực tài khoản của bạn.',
    loginAsPharmacist: 'Bạn đang đăng ký tài khoản dược sĩ.',
    loginAsPharmacistDescription:
      'Vui lòng cung cấp các thông tin chuyên môn giúp Sophie xác thực tài khoản của bạn.',
    loginAsPharmacy: 'Bạn đang đăng ký tài khoản nhà thuốc.',
    loginAsPharmacyDescription:
      'Vui lòng cung các thông tin về nhà thuốc giúp Sophie xác thực tài khoản của bạn.',
    loginAsNursing: 'Bạn đang đăng ký tài khoản điều dưỡng.',
    loginAsNursingDescription:
      'Các thông tin chuyên môn giúp Sophie xác thực tài khoản của bạn.',
    loginAsAdvisory: 'Bạn đang đăng kí tài khoản phòng thí nghiệm',
    loginAsAdvisoryDescription:
      'Vui lòng cung các thông tin về nhà thuốc giúp Sophie xác thực tài khoản của bạn.',
    byClickCompleted: 'Bằng việc nhấn “Hoàn tất” bạn đã đồng ý với',
    termsOfUse: 'Điều khoản sử dụng',
    and: 'và',
    privacyTerms: 'Điều khoản bảo mật',
    ofSophie: 'của Sophie',

    // form
    firstName: 'Họ và tên đệm',
    firstNamePlaceholder: 'Nhập họ và tên đệm',
    lastName: 'Tên',
    lastNamePlaceholder: 'Nhập tên của bạn',
    birthday: 'Ngày sinh',
    birthdayPlaceholder: 'Chọn ngày sinh',
    gender: 'Giới tính',
    genderPlaceholder: 'Chọn giới tính',
    email: 'Địa chỉ email',
    emailPlaceholder: 'Nhập địa chỉ email',

    degree: 'Học vị',
    degreePlaceholder: 'Chọn học vị của bạn',
    specialist: 'Chuyên khoa',
    specialistPlaceholder: 'Chọn chuyên khoa',
    findBySpecialistName: 'Tìm theo tên chuyên khoa',
    numExp: 'Năm kinh nghiệm',
    numExpPlaceholder: 'Nhập số năm kinh n...',
    year: 'Năm',
    uploadDetailImage: 'Upload ảnh chụp chi tiết',
    licenseImagesDoctor: 'Bằng cấp chuyên môn cao nhất của bạn',
    licenseImagesDoctorDescription:
      'Vui lòng chụp và upload ảnh chi tiết bằng cấp chuyên môn cao nhất của bạn.',

    hospitalName: 'Tên phòng khám',
    hospitalNamePlaceholder: 'Nhập tên phòng khám',
    hospitalAddress: 'Địa chỉ phòng khám, bệnh viện',
    clinicAddress: 'Địa chỉ phòng khám',
    hospitalSpecialist: 'Chuyên khoa tại phòng khám, bệnh viện',
    hospitalSpecialistDescription:
      'Vui lòng chọn các chuyên khoa khám bệnh tại phòng khám, bệnh viện của bạn.',
    addSpecialist: 'Thêm chuyên khoa',
    businessLicenseImagesHospital: 'Giấy phép hoạt động',
    advisoryName: 'Tên phòng thí nghiệm',
    advisoryNamePlaceholder: 'Nhập tên phòng thí nghiệm',
    advisoryAddress: 'Địa chỉ phòng thí nghiệm',

    licenseImagesPharmacist: 'Giấy phép hoạt động',
    licenseImagesPharmacistDescription:
      'Vui lòng chụp, và upload ảnh chi tiết bằng cấp chuyên môn cao nhất của bạn.',
    businessLicenseImagesPharmacist: 'Chứng chỉ hành nghề',
    businessLicenseImagesPharmacistDescription:
      'Vui lòng chụp, và upload ảnh chi tiết chứng chỉ hành nghề của bạn.',

    pharmacyName: 'Tên nhà thuốc',
    pharmacyNamePlaceholder: 'Nhập tên nhà thuốc',
    pharmacyAddress: 'Địa chỉ nhà thuốc',
    licenseImagesPharmacy: 'Giấy phép đăng ký kinh doanh',
    licenseImagesPharmacyDescription:
      'Vui lòng chụp, và upload ảnh chi tiết giấy phép đăng ký kinh doanh của nhà thuốc.',

    // complete
    registerComplete: 'Hoàn tất đăng ký tài khoản',
    registerCompleteDescription:
      'Sophie sẽ kiểm duyệt và liên hệ với bạn để xác thực các thông tin liên quan về tài khoản bạn đăng ký trong thời gian sớm nhất.',
    goHomepage: 'Về trang chủ',

    // patient
    alertRegisterSuccess:
      'Chúc mừng bạn đã hoàn tất đăng ký tài khoản. Chào mừng bạn đến với Sophie.',
  },
  login: `Đăng nhập`,
  password: `Mật khẩu`,
  forgot_password: `Quên mật khẩu`,
  forgotPasswordPage: {
    email_warning:
      'Hệ thống chưa hỗ trợ tính năng quên mật khẩu bằng email. Hãy nhập số điện thoại của bạn để thực hiện tính năng này',
    you_forgot_password: `Bạn quên mật khẩu`,
    input_otp: `Nhập mã xác thực`,
    create_new_password: `Tạo mật khẩu mới`,
    phone_required: `Nhập số điện thoại hoặc email của bạn`,
    validate_field: `Vui lòng nhập số điện thoại hoặc email đăng ký tài khoản bác sĩ, nhà
    thuốc để tạo mật khẩu mới.`,
    send_otp: `Gửi mã xác thực`,
    please_input_otp: `Vui lòng nhập mã xác thực`,
    sub_title: `Nhập mã OTP gồm 6 chữ số đã gửi đến `,
    mine: ` của bạn.`,
    verifyCode: `Mã xác thực`,
    youDontReceiveCode: `Bạn không nhận được mã?`,
    resendOtpCode: `Gửi lại mã xác thực`,
    resendOtpCodeAfter: `Gửi lại mã sau {{ countdown }} giây`,
    minPassword: 'Mật khẩu tối thiểu 8 ký tự',
    maxPassword: 'Mật khẩu tối đa 20 ký tự',
    validate_required_user:
      'Vui lòng nhập số điện thoại đăng ký để tạo mật khẩu mới.',
    phone_register: 'Số điện thoại đăng ký',
    phoneNumberNotExist: 'Số điện thoại không tồn tại',
    phone_require_user: 'Nhập số điện thoại của bạn',
    alertChangePasswordSuccess:
      'Mật khẩu của bạn đã được cập nhật. Vui lòng đăng nhập bằng mật khẩu mới.',
  },
  createNewPasswordPage: {
    pleaseInputNewPassword: `Vui lòng nhập mật khẩu mới`,
    newPassword: `Mật khẩu mới`,
    inputNewPassword: `Nhập mật khẩu mới`,
    passwordValidation: `Mật khẩu từ 8 đến 20 ký tự`,
    completed: `Hoàn tất`,
  },
  phone_or_email_register: `Số điện thoại hoặc email đăng ký`,
  input_phone_or_email: `Nhập số điện thoại hoặc email đăng ký`,

  continue: `Tiếp tục`,
  phoneNumber: `số điện thoại`,

  // Shop Online
  pharmacyOnline: {
    pharmacyOnline: 'Nhà thuốc online',
    productCategory: 'Danh mục sản phẩm',
    promotionProductInDay: 'Sản phẩm ưu đãi trong ngày',
    promotion: 'Chương trình khuyến mãi',
    seeAll: 'Xem tất cả',
    search: 'Tìm kiếm',
    headerSearchPlaceholder: 'Tìm theo tên, thương hiệu sản phẩm...',
    reset: 'Đặt lại',
    apply: 'Áp dụng',
    searchResultFor: 'Kết quả tìm kiếm cho',
    searchResultFound: 'Tìm thấy {{number}} kết quả.',
    noSearchResult: 'Không tìm thấy kết quả phù hợp',
    pleaseTryWithOtherSearch: 'Vui lòng thử với từ khoá tìm kiếm khác.',
    viewedProduct: 'Các sản phẩm đã xem',

    category: 'Danh mục',
    price: 'Giá bán',
    chooseRangePrice: 'Chọn khoảng giá',
    minPriceMustLessThanMaxPrice: 'Giá từ phải nhỏ hơn giá đến',
    ageRange: 'Đối tượng sử dụng',

    sortBestSale: 'Bán chạy nhất',
    sortNewest: 'Mới nhất',
    sortPriceLowest: 'Giá thấp nhất',
    under: 'Dưới',
    to: 'đến',
    above: 'Trên',

    product: {
      amount: 'Số lượng',
      buyNow: 'Mua ngay',
      addToCart: 'Thêm vào giỏ hàng',
      similarProduct: 'Sản phẩm tương tự',
      readMore: 'Xem thêm',
      collapse: 'Thu gọn',
    },
  },
  cart: {
    yourCart: 'Giỏ hàng của bạn',
    product: 'Sản phẩm',
    quantity: 'Số lượng',
    toPrice: 'Thành tiền',
    delete: 'Xoá',
    outStock: 'Tạm thời hết hàng',
    youNeedAddProductToCart: 'Bạn chưa thêm sản phẩm nào vào giỏ hàng',
    deletedProductFormCart: 'Đã xoá sản phẩm khỏi giỏ hàng',
    addedProductToCart: '{{name}} đã được thêm vào giỏ hàng',
    productOutStock: 'Sản phẩm {{name}} hết hàng',

    pay: 'Thanh toán',
    payDetail: 'Chi tiết thanh toán',
    understand: 'Tôi đã hiểu',
    change: 'Thay đổi',

    shippingUnit: 'Đơn vị vận chuyển',
    deliveryTime: 'Nhận hàng vào:',

    shipTo: 'Giao đến',
    address: 'Địa chỉ',
    selectAddress: 'Chọn địa chỉ giao hàng',
    defaultAddress: 'Địa chỉ mặc định',
    shipToOtherAddress: 'Giao hàng đến địa chỉ khác?',
    addNewAddress: 'Thêm địa chỉ mới',
    homePlace: 'Nhà riêng',
    workPlace: 'Cơ quan',

    promotion: 'Mã giảm giá',
    transportPromotion: 'Mã giảm giá vận chuyển',
    addPromotion: 'Thêm mã giảm giá',
    inputPromotion: 'Nhập mã giảm giá',
    originalPrice: 'Tạm tính',
    transportPrice: 'Phí giao hàng',
    total: 'Tổng',
    apply: 'Áp dụng',
    exp: 'HSD',
    enterOrSelectPromotionBelow:
      'Nhập mã giảm giá hoặc chọn các mã giảm giá bên dưới',

    paymentMethod: 'Phương thức thanh toán',
    selectPaymentMethod: 'Chọn phương thức thanh toán',
    eWallet: 'Ví điện tử',
    atmCard: 'Thẻ ATM ngân hàng',
    addCard: 'Thêm thẻ khác',
    codMethod: 'Tiền mặt khi nhận hàng',
    momoMethod: 'Ví Momo',
    zaloMethod: 'Zalo Pay',

    orderSuccess: 'Đặt hàng thành công',
    orderSuccessSubText: 'Cám ơn bạn đã mua sắm cùng Sophie.',
    orderFailedSubText:
      'Bạn vui lòng thử lại hoặc thanh toán bằng phương thức khác.',
    paySuccess: 'Đã thanh toán',
    payFailed: 'Thanh toán thất bại',
    viewDetail: 'Xem chi tiết',
    goHomepage: 'Về trang chủ',
    rePay: 'Thanh toán lại',

    orderDetail: 'Chi tiết đơn hàng',
    orderInfo: 'Thông tin đơn hàng',
    orderCode: 'Mã đơn hàng',
    orderDate: 'Ngày đặt hàng',
    orderReceiver: 'Người nhận',

    deleteProduct: 'Xoá sản phẩm',
    deleteProductConfirm: 'Bạn muốn xoá sản phẩm này khỏi giỏ hàng?',
    alertHasOverSellTitle: 'Bạn đang có sản phẩm tạm thời hết hàng',
    alertHasOverSellDescription:
      'Các sản phẩm này sẽ không được đưa vào bước đặt hàng.?',
    alertLackAddress: 'Bạn chưa có địa chỉ giao hàng',
    alertOrderSuccess: 'Đặt hàng thành công',

    created: 'Đã tạo đơn hàng',
    verified: 'Đã xác nhận đơn',
    waitingForDelivery: 'Chờ lấy hàng',
    delivered: 'Đã giao cho đơn vị vận chuyển',
    transporting: 'Đang vận chuyển',
    delivering: 'Đang giao',
    success: 'Đã giao thành công',
    waiting: 'Chờ giao lại',
    refunding: 'Đang hoàn hàng',
    refunded: 'Đã hoàn hàng',
    cancel: 'Đã hủy đơn hàng',
  },

  userProfile: {
    homepage: 'Trang chủ',
    saveChange: 'Lưu Thay đổi',
    menu: {
      personalInformation: 'Thông tin cá nhân',
      addressList: 'Danh sách địa chỉ',
      paymentMethod: 'Phương thức thanh toán',
      orderTracking: 'Theo dõi đơn hàng',
    },
    personalInformation: {
      title: 'Thông tin cá nhân',
      updateInformation: 'Cập nhật thông tin',
      phoneNumber: 'Số điện thoại',
      password: 'Mật khẩu',
      changePassword: 'Đổi mật khẩu',
      firstName: 'Họ và tên đệm',
      lastName: 'Tên',
      birthday: 'Ngày sinh',
      email: 'Email',
      gender: 'Giới tính',

      forgotPassword: 'Quên mật khẩu',
      currentPassword: 'Mật khẩu hiện tại',
      inputCurrentPassword: 'Nhập mật khẩu hiện tại',
      newPassword: 'Mật khẩu mới',
      inputNewPassword: 'Nhập mật khẩu mới',
      confirmPassword: 'Nhập lại mật khẩu mới',
      saveNewPassword: 'Lưu mật khẩu mới',
      updateSuccess: 'Cập nhật thông tin cá nhân thành công',
    },
    address: {
      listAddress: 'Danh sách địa chỉ',
      addAddress: 'Thêm địa chỉ',
      addNewAddress: 'Thêm địa chỉ mới',
      updateAddress: 'Sửa địa chỉ',
      saveNewAddress: 'Lưu địa chỉ mới',

      contact: 'Người liên hệ',
      inputContact: 'Nhập họ và tên người liên hệ',
      phoneNumber: 'Số điện thoại',
      inputPhoneNumber: 'Nhập số điện thoại người liên hệ',
      province: 'Tỉnh, thành phố',
      selectProvince: 'Chọn tỉnh, thành phố',
      district: 'Quận, huyện',
      selectDistrict: 'Chọn quận, huyện',
      wards: 'Phường, xã',
      selectWards: 'Chọn phường, xã',
      address: 'Địa chỉ',
      inputAddress: 'Nhập địa chỉ',
      addressType: 'Loại địa chỉ',
      home: 'Nhà riêng',
      organ: 'Cơ quan',
      setDefaultAddress: 'Đặt địa chỉ làm mặc định',

      deleteAddress: 'Xoá địa chỉ',
      deleteAddressConfirm: 'Bạn có muốn xoá địa chỉ này không',
      deleteAddressSuccess: 'Xoá địa chỉ thành công',
      deleteAddressFailed: 'Xoá địa chỉ thất bại',
    },

    PAYMENT_METHODS: 'Phương thức thanh toán',
    ORDER_TRACKING: 'Theo dõi đơn hàng',
  },

  // USER
  'MENU/ABOUTSOPHIE': 'Về Sophie',
  'MENU/ABOUTSOPHIE/INTRODUCE': 'Giới thiệu',
  'MENU/ABOUTSOPHIE/HR': 'Nhân sự, tuyển dụng',
  'MENU/BOOKING_ONLINE': 'Đặt khám Online',
  'MENU/BOOKING_ONLINE/PHONE': 'Đặt khám qua điện thoại',
  'MENU/BOOKING_ONLINE/HOME': 'Đặt khám tại nhà',
  'MENU/BOOKING_ONLINE/CLINIC': 'Đặt khám tại phòng khám',
  'MENU/BOOKING_ONLINE/FASTPHONE': 'Khám nhanh qua điện thoại',
  'MENU/SERVICE_HEALTH_AT_HOME': 'Dịch vụ sức khoẻ tại nhà',
  'MENU/SERVICE_HEALTH_AT_HOME/BABY': 'Dịch vụ tắm bé',
  'MENU/SERVICE_HEALTH_AT_HOME/SICK': 'Chăm sóc người ốm',
  'MENU/SERVICE_HEALTH_AT_HOME/BLOOD_AT_HOME': 'Lấy máu tại nhà',
  'MENU/SERVICE_HEALTH_AT_HOME/THERAPY': 'Vật lý trị liệu',
  'MENU/PHARMARCY_ONLINE': 'Nhà thuốc Online',
  'MENU/BLOG_HEALTH': 'Blog sức khoẻ',
  'MENU/SIGN_UP': 'Đăng ký',
  'MENU/LOGIN': 'Đăng nhập',
  HOMEPAGE: {
    SERVICE: {
      DOWNLOAD: 'Tải ứng dụng cho bác sĩ',
      TITLE: 'Dịch vụ khám và đặt khám bệnh <span>trực tuyến của Sophie</span>',
      PHONE: 'Đặt khám qua điện thoại',
      HOME: 'Đặt khám tại nhà',
      CLINIC: 'Đặt khám tại phòng khám',
      PHONE_FAST: 'Khám nhanh qua điện thoại',
      SUB_PHONE: 'Các bác sĩ sẽ gọi điện, thăm khám và tư vấn cho bạn',
      SUB_HOME: 'Các bác sĩ thăm khám tại nhà theo lịch hẹn',
      SUB_CLINIC: 'Chọn phòng khám, và đặt lịch hẹn theo chuyên khoa',
      SUB_PHONE_FAST: 'Gọi nhanh cho các bác sĩ trực cho tình huống khẩn cấp',
    },
    SERVICE_OFFLINE: {
      TITLE: 'Dịch vụ sức khoẻ tại nhà <span>của Sophie</span>',
      SUBTITLE: 'Chăm sóc tại nhà thảnh thơi với Sophie.',
      BABY: 'Dịch vụ tắm bé',
      SUB_BABY: 'Tắm bé sơ sinh tại nhà',
      SICK: 'Chăm sóc người ốm',
      SUB_SICK: 'Điều dưỡng, chăm sóc tại nhà',
      BLOOD_HOME: 'Lấy máu tại nhà',
      SUB_BLOOD_HOME: 'Lấy mẫu xét nghiệm tại nhà',
      THERAPY: 'Vật lý trị liệu',
      SUB_THERAPY: 'Bác sĩ, điều dưỡng chuyên môn chăm sóc, điều trị tại nhà',
    },
    SOPHIE_CONFIDENT: {
      TITLE: 'Sophie hoàn toàn tự tin <span>phục vụ tốt nhu cầu của bạn</span>',
      SUBTITLE: `Thăm khám qua điện thoại, hay khám tại nhà, hoặc đặt lịch tại
      phòng khám tiện lợi. Đặt mua nhanh theo đơn, hoặc qua tư vấn,
      không còn phải loay hoay tìm thuốc bạn cần.`,
      SUB_SUBTITLE: 'Ứng dụng có mặt trên',
      COUNT_HOSPITAL: 'Bệnh viện, phòng khám, nhà thuốc liên kết',
      COUNT_DOCTOR: 'Bác sĩ, y sĩ, dược sĩ, điều dưỡng đăng ký',
      COUNT_PATIENT: 'Bệnh nhân, khách hàng đã tin tưởng sử dụng',
      COUNT_APPOINTMENT: 'Lượt đặt khám trực tuyến, dịch vụ sức khoẻ tại nhà',
    },
    TOP_PARTNER: {
      TITLE: 'Các bác sĩ, dược sĩ, y sĩ hàng đầu',
      AT_SOPHIE: 'tại Sophie',
      SUBTITLE: `Hơn 3.000 bác sĩ, dược sĩ, y sĩ,... đang liên kết cùng chăm sóc các bệnh nhân tại Sophie.`,
    },
    PRODUCT_CATEGORY: {
      TITLE: 'Danh mục sản phẩm',
      SEE_ALL: 'Xem tất cả',
      PROMOTION_IN_DAY: 'Sản phẩm ưu đãi trong ngày',
      PROMOTION_TITLE: 'Chương trình khuyến mãi',
    },
    BLOG: {
      TITLE: 'Blog <span>Sức khoẻ</span>',
      SUBTITLE: `Theo dõi Sophie để hiểu rõ và nâng cao sức khoẻ cho cả gia đình
      bạn nhé.`,
    },
  },
  ABOUT_SOPHIE: {
    PART1: {
      TITLE: 'Giới thiệu về',
      SUBTITLE: 'Ứng dụng Sophie',
      PARAGRAPH: `Sophie là ứng dụng nền tảng cung cấp dịch vụ đặt khám, tư vấn sức
      khoẻ trực tuyến, dịch vụ đặt mua thuốc và giao thuốc tại nhà, các
      dịch vụ chăm sóc sức khoẻ tại nhà khác: khám, lấy máu, vật lý trị
      liệu…. Tại Sophie, bệnh nhân sẽ được chăm sóc kịp thời với đội ngũ
      nhân viên y tế có chuyên môn cao, kinh nghiệm và nhiệt huyết.`,
    },
    PART2: {
      TITLE: 'Sứ mệnh và tầm nhìn',
      TITLE2: 'của Sophie',
      SUBTITLE: 'Đặt sức khoẻ bệnh nhân là trung tâm của mọi sự chăm sóc.',
      PARAGRAPH: `Thực trạng hiện nay, có rất nhiều bệnh nhân vì nhiều lý do không
      thể tiếp cận được dịch vụ chăm sóc sức khoẻ kịp lúc. Quỹ bảo
      hiểm y tế toàn dân không thể đáp ứng hết tất cả nhu cầu chăm sóc
      sức khoẻ của người dân. Sức khoẻ của gần 100.000.000 người dân
      Việt Nam phải được chăm sóc bằng sự hợp sức của toàn bộ nhân
      viên y tế được đào tạo bài bản và có đạo đức hành nghề.`,
      PARAGRAPH2: `Sophie sẽ trở thành ứng dụng y tế phổ biến nhất để chăm sóc
      sức khoẻ của mọi nhà trong vòng 05 năm tới tại Việt Nam.`,
    },
    PART3: {
      TITLE: 'Ban điều hành',
      SUBTITLE: 'của Sophie',
      SUBSUBTITLE: `Với nền tảng chuyên môn nhiều năm trong lĩnh vực y tế, đội ngũ điều hành hoạt động của chúng tôi là những bác sĩ, dược sĩ công tác trong bệnh viện.`,
      PARAGRAPH: `Chúng tôi mong muốn đem lại sự trải nghiệm tốt nhất và kết quả như mong đợi với các dịch vụ trên sàn thương mại điện tử Sophie.`,
      DOCTOR1: 'DS. CKI. Trần Quốc Thắng',
      DOCTOR1_DESCRIPTION: 'Đa khoa - BV ĐH Y Dược',
      DOCTOR2: 'DS. CKI. Phan Vũ Cẩm Nhung',
      DOCTOR2_DESCRIPTION: 'Đa khoa - BV ĐH Y Dược',
      DOCTOR3: 'BS. TS Trần Thanh Vinh',
      DOCTOR3_DESCRIPTION: 'Khoa da liễu - BV Da liễu TP.HCM',
      DOCTOR4: 'BS. Ngô Minh Tuyết',
      DOCTOR4_DESCRIPTION: 'Khoa da liễu - BV Da liễu TP.HCM',
      DOCTOR5: 'BS. TS Trần Thanh Vinh',
      DOCTOR5_DESCRIPTION: 'Khoa thần kinh - BV ĐH Y Dược',
      DOCTOR6: 'BS. Ngô Minh Tuyết',
      DOCTOR6_DESCRIPTION: 'Khoa da liễu - BV Da liễu TP.HCM',
    },
  },
  SOPHIE_HIRING: {
    PART_1: {
      TITLE: 'Môi trường làm việc',
      SUBTITLE: 'tại Sophie',
      PARAGRAPH: `Nhân viên làm việc trong môi trường an toàn, thân thiện
      với thái độ cầu thị. Mọi khó khăn vướng mắc đều có thể
      vượt qua nếu chúng ta cùng nhau tư duy và giải quyết. Mỗi
      cá thể là một thành tố tạo nên thành công của Sophie.`,
      TITLE_1: 'Con người',
      SUBTITLE_1: 'của Sophie',
      PARAGRAPH_1: `Chúng tôi cung cấp dịch vụ cho người bệnh nên chúng tôi
      xem giá trị con người lớn hơn giá trị của lợi ích. Và giá
      trị của công ty chính là sự hạnh phúc và hài lòng của nhân
      viên và khách hàng.`,
    },
    PART_2: {
      TITLE: 'Các vị trí đang tuyển dụng',
      PLACEHOLDER: 'Tìm theo tên vị trí',
      LABEL: 'Dược sĩ tư vấn nhà thuốc',
      PLACE: 'Địa điểm:',
      QUANTITY: 'Số lượng:',
      DATE: 'Ngày đăng:',
      DETAIL: 'Chi tiết',
    },
  },
  SOPHIE_HIRING_DETAIL: {
    TITLE: '<span>Nhân sự, tuyển dụng</span> / Dược sĩ quản trị thực tập',
    SUBTITLE: 'Nội dung tuyển dụng',
  },
  NAV_RIGHT: {
    TITLE: 'TÌM KIẾM VỊ TRÍ TUYỂN DỤNG',
    PLACEHOLDER: 'Nhập từ khoá tìm kiếm',
    SUBTITLE: 'VỊ TRÍ HOT',
    LABEL: 'Dược sĩ tư vấn nhà thuốc',
  },
  GENERAL: {
    INPUT: 'Nhập thông tin địa chỉ',
    WHERE_APP: 'Ứng dụng có mặt trên',
    FIND_DOCTOR: 'Tìm kiếm bác sĩ theo chuyên khoa',
    FIND_TECH_MEMBER: 'Tìm kiếm kỹ thuật viên, chuyên viên',
    DATE_CARE: 'Đặt lịch khám phù hợp',
    CHECKOUT: 'Thanh toán phí đặt khám',
    CONSULTING: 'Bác sĩ thăm khám và tư vấn',
    RATE: 'Hệ thống đánh giá, uy tín',
    FOLLOW: 'Dễ dàng theo dõi lịch đặt',
    FOLLOW_CARE: 'Dễ dàng theo dõi lịch khám',
  },
  APPOINTMENT: {
    PHONE: {
      TITLE: 'Đặt khám <span>qua điện thoại</span>',
      CALL: 'Các bác sĩ sẽ gọi điện, thăm khám và tư vấn cho bạn.',
      DESC: 'Bạn gặp vấn đề bất thường về sức khoẻ hay có  thắc mắc về sức khoẻ của mình hoặc người thân. Bạn có thể đặt lịch tư vấn qua điện thoại với các bác sĩ đúng chuyên khoa, có chuyên môn và kinh nghiệm. Việc đặt tư vấn sẽ giúp bạn tiết kiệm thời gian đi lại, chờ đợi mà vẫn có thể hiểu rõ hơn về sức khoẻ của mình và người thân.',
      CONNECT: 'Kết nối với các bác sĩ hàng đầu',
      PROCESS: '<span>Quy trình</span> <span>đặt khám qua điện thoại</span>',
      SUB: 'Đặt khám qua điện thoại nhanh chóng, đơn giản và thuận tiện chỉ với 4 bước trên ứng dụng Sophie.',
    },
    HOME: {
      TITLE: '<span>Đặt khám</span> tại nhà',
      HOME: 'Các bác sĩ thăm khám tại nhà theo lịch hẹn.',
      DESC: 'Bạn không muốn đi ra ngoài mà vẫn muốn được thăm khám trực tiếp. Bạn đặt dịch vụ khám tại nhà qua ứng dụng Sophie. Bác sĩ sẽ tới khám tại nhà để kiểm tra tình hình sức khoẻ, đưa ra các lời khuyên giúp bạn cải thiện sức khoẻ. Dịch vụ rất phù hợp cho những người khó khăn trong việc đi lại, những người không có thời gian tới khám tại các cơ sở y tế.',
      PROCESS: '<span>Quy trình</span> <span>đặt khám tại nhà</span>',
      SUB: 'Đặt khám tại nhà nhanh chóng, đơn giản và thuận tiện chỉ với 4 bước trên ứng dụng Sophie.',
      CALENDAR: 'Bác sĩ thăm khám tại nhà theo lịch',
    },
    HOSPITAL: {
      TITLE: '<span>Đặt khám</span> tại phòng khám',
      CHOOSE: 'Chọn phòng khám, và đặt lịch hẹn theo chuyên khoa.',
      DESC: 'Phòng khám quá đông thường phải chờ đợi, Bạn không biết được lịch mở cửa của phòng khám gần những đợt nghỉ dài. Hãy để Sophie hẹn lịch khám tại phòng khám cho bạn miễn phí. Thông qua Sophie, Bác sĩ cũng theo dõi được tình hình sức khoẻ của bạn sau mỗi đợt khám. ',
      CONNECT: 'Kết nối với các bệnh viện, phòng khám',
      PROCESS: '<span>Quy trình</span> <span>đặt khám tại phòng khám</span>',
      SUB: 'Đặt khám tại phòng khám nhanh chóng, đơn giản và thuận tiện chỉ với 3 bước trên ứng dụng Sophie.',
      GO: 'Đến phòng khám theo lịch',
      DATE: 'Chọn ngày, giờ đặt khám',
      MEMBER: 'Chọn phòng khám và chuyên khoa',
    },
    FAST_PHONE: {
      TITLE: '<span>Tư vấn nhanh</span> qua điện thoại',
      FAST_CALL: 'Gọi nhanh cho các bác sĩ trực dành cho tình huống khẩn cấp.',
      DESC: 'Bạn gặp phải vấn đề gấp cần có sự tư vấn chuyên môn của nhân viên y tế. Ngay lập tức qua ứng dụng Sophie, bạn sẽ được kết nối với bác sĩ trực của chúng tôi. Bác sĩ sẽ đưa ra những lời khuyên cần thiết để giúp bạn bớt lo lắng hơn và sẽ có cách xử trí phù hợp hơn trong những lúc gấp gáp. ',
      ONLINE: 'Các bác sĩ trực 24/7',
    },
  },
  CONTACT: {
    CONTACT_WITH: 'Liên hệ với',
    SHOPIE: 'Sophie',
    QUESTION:
      'Nếu bạn có thắc mắc, hay cần trợ giúp đừng ngần ngại liên hệ Sophie nhé!',
    ADDRESS: 'Địa chỉ',
    DETAIL_ADDRESS:
      'Số 8, đường số 11, khu phố 6, Tổ 40B, P. Hiệp Bình Chánh, TP. Thủ Đức',
    CONTACT_SUPPORT: 'Liên hệ hỗ trợ',
    NUMBER: '0989 260 818',
    MAIL: 'sophievietnam.healthcare@gmail.com',
    WORKING_TIME: 'Giờ làm việc',
    TIME: 'Thứ 2 đến thứ 6:',
    TIME_AT: '8h00 - 18h00',
    TIME_ON: 'Thứ 7, Chủ nhật:',
    TIME_FOR: '8h00 - 12h00',
  },
  POLICY: {
    TITLE: 'Chính sách của Sophie',
    SECURITY: 'Chính sách bảo mật',
    CHECKOUT: 'Chính sách thanh toán',
    SHIP: 'Chính sách giao hàng',
    MEDICIN: 'Chính sách đổi trả thuốc',
    PROTECT: 'Chính sách bảo vệ người dùng',
    GENERAL: {
      TITLE: 'CHÍNH SÁCH & QUY ĐỊNH CHUNG',
      SUB_TITLE: 'I. Chính sách chung',
      ACCEPT: '1. Chấp thuận các Điều kiện Sử dụng',
      NOTE1:
        ' Khi sử dụng Website của CÔNG TY TNHH CÔNG TY TNHH SOPHIE HEALTHCARE VIỆT NAM, Quý khách đã mặc nhiên chấp thuận các điều khoản và điều kiện sử dụng được quy định dưới đây. Để biết được các sửa đổi mới nhất, Quý khách nên thường xuyên kiểm tra lại “Hướng dẫn mua hàng”. Chúng tôi có quyền thay đổi, điều chỉnh, thêm hay bớt các nội dung của “Hướng dẫn mua hàng” tại bất kỳ thời điểm nào. Nếu Quý khách vẫn tiếp tục sử dụng Website sau khi có các thay đổi như vậy thì có nghĩa là Quý khách đã chấp thuận các thay đổi đó.',
      NOTE2: '2. Tính chất của thông tin hiển thị',
      DESC: 'Các nội dung hiển thị trên Website nhằm mục đích cung cấp thông tin về CÔNG TY TNHH CÔNG TY TNHH SOPHIE HEALTHCARE VIỆT NAM , về thông tin sản phẩm và dịch vụ mà mà chúng tôi đang cung cấp. Các thông tin khác liên quan nhằm phục vụ nhu cầu tìm hiểu của khách hàng đều được ghi rõ nguồn cung cấp.',
      LINK: '3. Liên kết đến Website khác',
      NOTICE1:
        'Website có thể cung cấp một số liên kết tới trang Web hoặc nguồn dữ liệu khác. Quý khách tự chịu trách nhiệm khi sử dụng các liên kết này. CÔNG TY TNHH CÔNG TY TNHH SOPHIE HEALTHCARE VIỆT NAM không tiến hành thẩm định hay xác thực nội dung, tính chính xác, quan điểm thể hiện tại các trang Web và nguồn dữ liệu liên kết này. Chúng tôi từ chối bất cứ trách nhiệm pháp lý nào liên quan tới tính chính xác, nội dung thể hiện, mức độ an toàn và việc cho hiển thị hay che đi các thông tin trên các trang Web và nguồn dữ liệu nói trên.',
      NOTICE2: '4. Liên kết từ Website khác',
      COMPANY:
        'CÔNG TY TNHH CÔNG TY TNHH SOPHIE HEALTHCARE VIỆT NAM không cho phép bất kỳ nhà cung cấp dịch vụ internet nào được phép “đặt toàn bộ” hay “nhúng” bất kỳ thành tố nào của Website này sang một trang khác hoặc sử dụng các kỹ thuật làm thay đổi giao diện / hiển thị mặc định của Website mà chưa có sự đồng ý của chúng tôi',
      COMPANY2: '5. Miễn trừ trách nhiệm',
      SHOPIE:
        'Thông tin hiển thị tại website này không đi kèm bất kỳ đảm bảo hay cam kết trách nhiệm từ phía CÔNG TY TNHH CÔNG TY TNHH SOPHIE HEALTHCARE VIỆT NAM về sự phù hợp của sản phẩm, dịch vụ mà người mua đã lựa chọn',
      REJECT:
        'CÔNG TY TNHH CÔNG TY TNHH SOPHIE HEALTHCARE VIỆT NAM từ chối trách nhiệm hay đưa ra đảm bảo rằng website sẽ không có lỗi vận hành, an toàn, không bị gián đoạn hay tính chính xác, đầy đủ và đúng hạn của các thông tin hiển thị',
      ACCESS:
        'Khi truy cập vào website này, quý khách mặc nhiên đồng ý rằng CÔNG TY TNHH CÔNG TY TNHH SOPHIE HEALTHCARE VIỆT NAM, các nhà cung cấp khác cùng với đối tác liên kết, nhân viên của họ không chịu bất cứ trách nhiệm nào liên quan đến thương tật, mất mát, khiếu kiện, thiệt hại trực tiếp hoặc gián tiếp do không lường trước hoặc do hậu quả để lại dưới bất cứ hình thức nào phát sinh từ việc',
      LIST_NOTICE:
        '(1) Sử dụng các thông tin trên website này; (2) các truy cập kết nối từ website này; (3) Đăng ký thành viên, đăng ký nhận thư điện tử hay tham gia vào chương trình khách hàng thường xuyên của CÔNG TY TNHH CÔNG TY TNHH SOPHIE HEALTHCARE VIỆT NAM',
      CONDITION:
        'Các điều kiện và hạn chế nêu trên chỉ có hiệu lực trong khuôn khổ pháp luật hiện hành.',
      BRAIN: '6. Quyền sở hữu trí tuệ',
      WEB: 'Website này và mọi nội dung xếp đặt, hiển thị đều thuộc sở hữu và là tài sản độc quyền khai thác của CÔNG TY TNHH CÔNG TY TNHH SOPHIE HEALTHCARE VIỆT NAM và các nhà cung cấp có liên quan khác. Mọi sử dụng, trích dẫn phải không gây thiệt hại cho CÔNG TY TNHH CÔNG TY TNHH SOPHIE HEALTHCARE VIỆT NAM và đều phải tuân thủ các điều kiện sau: (1) Chỉ sử dụng cho mục đích cá nhân, phi thương mại; (2) các sao chép hoặc trích dẫn đều phải giữ nguyên dấu hiệu bản quyền hoặc các yết thị về quyền sở hữu trí tuệ như đã thể hiện trong phiên bản gốc; và Tất cả các nội dung được cung cấp tại Website này không được phép nhân bản, hiển thị, công bố, phổ biến, đưa tin tức hay lưu hành cho bất cứ ai, dưới bất kỳ hình thức nào, kể cả trên các Website độc lập khác mà không được sự chấp thuận của CÔNG TY TNHH CÔNG TY TNHH SOPHIE HEALTHCARE VIỆT NAM.',
      FIX: '7. Điều chỉnh và sửa đổi',
      SHUTDOWN:
        'CÔNG TY TNHH CÔNG TY TNHH SOPHIE HEALTHCARE VIỆT NAM bảo lưu quyền thay đổi, chỉnh sửa hoặc chấm dứt hoạt động của Website này vào bất cứ thời điểm nào.',
      CHECKOUT: '8. Quy định và hình thức thanh toán',
      PRODUCT:
        'Các sản phẩm được hiển thị trên website này nhằm mục đích giới thiệu, quảng bá. Các đơn vị, cá nhân quan tâm sản phẩm có thể liên hệ qua email hoặc trao đổi trực tiếp qua điện thoại để thống nhất cách thanh toán. Website này không có chức năng thanh toán trực tuyến.',
      SHIP: '9. Chính sách vận chuyển',
      MORE: 'Xem thêm tại phần chính sách vận chuyễn',
      PAY: '10. Chính sách đổi trã, hoàn tiền',
      CHANGE_CODE: 'Xem thêm tại phần chính sách đổi trã',
      PERSONAL: '11. Chính bảo vệ thông tin cá nhân',
      WATCH_MORE: 'Xem thêm tại phần chính sách bảo mật thông tin',
    },
    SECURITY_PAGE: {
      TITLE: 'CHÍNH SÁCH BẢO MẬT THÔNG TIN CÁ NHÂN',
      NOTICE:
        'Chính sách bảo mật thông tin này là cam kết của CÔNG TY TNHH CÔNG TY TNHH SOPHIE HEALTHCARE VIỆT NAM với tất cả các Khách hàng tham gia mua hàng qua Trang thương mại điện tử bán hàng của CÔNG TY TNHH CÔNG TY TNHH SOPHIE HEALTHCARE VIỆT NAM. Trên tinh thần thượng tôn pháp luật và bảo vệ tối đa quyền lợi người tiêu dùng, CÔNG TY TNHH CÔNG TY TNHH SOPHIE HEALTHCARE VIỆT NAM luôn mong muốn hướng đến sự hoàn thiện nhất trong các chính sách của mình. Nhằm phục vụ và cung cấp tốt hơn cho Khách hàng chúng tôi tiến hành thu thập thông tin của Khách hàng và cam kết bảo mật như sau:',
      INFO: '1. Mục đích và Phạm vi thu thập thông tin',
      WEB: 'Các thông tin thu thập thông qua website https://1sophie.com sẽ giúp chúng tôi:',
      SUPPORT: 'Hỗ trợ khách hàng khi mua sản phẩm',
      CUS: 'Giải đáp thắc mắc khách hàng',
      PROVIDER:
        'Cung cấp cho bạn thông tin mới nhất trên Website của chúng tôi',
      JUDGE: 'Xem xét và nâng cấp nội dung và giao diện của Website',
      MAKETING:
        'Thực hiện các hoạt động quảng bá liên quan đến các sản phẩm và dịch vụ của 1sophie.com',
      SERVICE:
        'Để truy cập và sử dụng một số dịch vụ tại 1sophie.com, quý khách có thể sẽ được yêu cầu đăng ký với chúng tôi thông tin cá nhân (Email, Họ tên, Số ĐT liên lạc…). Mọi thông tin khai báo phải đảm bảo tính chính xác và hợp pháp, chúng tôi không chịu mọi trách nhiệm liên quan đến pháp luật của thông tin khai báo.',
      CONNECT:
        'Chúng tôi cũng có thể thu thập thông tin về số lần viếng thăm, bao gồm số trang quý khách xem, số links (liên kết) bạn click và những thông tin khác liên quan đến việc kết nối đến 1sophie.com. Chúng tôi cũng thu thập các thông tin mà trình duyệt Web (Browser) quý khách sử dụng mỗi khi truy cập vào website 1sophie.com , bao gồm: địa chỉ IP, loại Browser, ngôn ngữ sử dụng, thời gian và những địa chỉ mà Browser truy xuất đến.',
      MAP: '2.Phạm vi sử dụng thông tin',
      ACCEPT2:
        '1sophie.com thu thập và sử dụng thông tin cá nhân quý khách với mục đích phù hợp và hoàn toàn tuân thủ nội dung của “Chính sách bảo mật” này.',
      NEED: 'Khi cần thiết, chúng tôi có thể sử dụng những thông tin này để liên hệ trực tiếp với bạn dưới các hình thức như: gởi thư ngỏ, đơn đặt hàng, thư cảm ơn, thông tin về kỹ thuật và bảo mật, quý khách có thể nhận được thư định kỳ cung cấp thông tin sản phẩm, dịch vụ mới, thông tin về các sự kiện sắp tới hoặc thông tin tuyển dụng nếu quý khách đăng kí nhận email thông báo.',
      EXCEPT:
        'Ngoại trừ các trường hợp về Sử dụng thông tin cá nhân như đã nêu trong chính sách này, chúng tôi cam kết sẽ không tiết lộ thông tin cá nhân bạn ra ngoài.',
      CASE: 'Trong một số trường hợp, chúng tôi có thể thuê một đơn vị độc lập để tiến hành các dự án nghiên cứu thị trường và khi đó thông tin của bạn sẽ được cung cấp cho đơn vị này để tiến hành dự án. Bên thứ ba này sẽ bị ràng buộc bởi một thỏa thuận về bảo mật mà theo đó họ chỉ được phép sử dụng những thông tin được cung cấp cho mục đích hoàn thành dự án.',
      LAW: 'Chúng tôi có thể tiết lộ hoặc cung cấp thông tin cá nhân của bạn trong các trường hợp thật sự cần thiết như sau: (a) khi có yêu cầu của các cơ quan pháp luật; (b) trong trường hợp mà chúng tôi tin rằng điều đó sẽ giúp chúng tôi bảo vệ quyền lợi chính đáng của mình trước pháp luật; (c) tình huống khẩn cấp và cần thiết để bảo vệ quyền an toàn cá nhân của các thành viên braun-heater.com khác.',
      TIME: '3.Thời gian lưu trữ thông tin',
      SAVE: 'Chúng tôi sẽ lưu trữ các Thông tin cá nhân do Khách hàng cung cấp trên hệ thống của chúng tôi trong quá trình cung cấp dịch vụ cho khách hàng hoặc cho đến khi hoàn thành mục đích thu thấp hoặc khi Khách hàng có yêu cầu hủy các thông tin đã cung cấp.',
      ADDRESS: '4.Địa chỉ của Đơn vị thu thập và Quản lý thông tin cá nhân',
      NAME: 'Tên đơn vị: CÔNG TY TNHH CÔNG TY TNHH SOPHIE HEALTHCARE VIỆT NAM',
      PHONE: 'Địa chỉ ĐKKD: 0317352307',
      VN: 'Địa điểm Kinh doanh: Số 8, đường số 11, Khu phố 6, Tổ 40B, Phường Hiệp Bình Chánh, Thành phố Thủ Đức, Thành phố Hồ Chí Minh, Việt Nam',
      WHENEVER:
        'Bất cứ thời điểm nào quý khách cũng có thể truy cập và chỉnh sửa những thông tin cá nhân của mình theo các liên kết (website’s links) thích hợp mà chúng tôi cung cấp.',
      COMMIT: '5.Cam kết bảo mật thông tin cá nhân khách hàng',
      TOAST:
        'Khi bạn gửi thông tin cá nhân của bạn cho chúng tôi, bạn đã đồng ý với các điều khoản mà chúng tôi đã nêu ở trên, 1sophie.com cam kết bảo mật thông tin cá nhân của quý khách bằng mọi cách thức có thể. Chúng tôi sẽ sử dụng nhiều công nghệ bảo mật thông tin khác nhau như: chuẩn quốc tế PCI, SSL,… nhằm bảo vệ thông tin này không bị truy lục, sử dụng hoặc tiết lộ ngoài ý muốn.',
      BECAUSE:
        'Tuy nhiên do hạn chế về mặt kỹ thuật, không một dữ liệu nào có thể được truyền trên đường truyền internet mà có thể được bảo mật 100%. Do vậy, chúng tôi không thể đưa ra một cam kết chắc chắn rằng thông tin quý khách cung cấp cho chúng tôi sẽ được bảo mật một cách tuyệt đối an toàn, và chúng tôi không thể chịu trách nhiệm trong trường hợp có sự truy cập trái phép thông tin cá nhân của quý khách như các trường hợp quý khách tự ý chia sẻ thông tin với người khác… Nếu quý khách không đồng ý với các điều khoản như đã mô tả ở trên, Chúng tôi khuyên quý khách không nên gửi thông tin đến cho chúng tôi.',
    },
    PAYBACK: {
      TITLE: 'CHÍNH SÁCH TRẢ HÀNG VÀ HOÀN TIỀN',
      CONDITION: '1. Điều kiện áp dụng',
      PRODUCT:
        'Áp dụng cho tất cả các sản phẩm đang kinh doanh tại https://1sophie.com',
      BACK: '2. Điều kiện trả hàng',
      CUS: 'Khách hàng có quyền ĐỔI sản phẩm khác hoặc TRẢ sản phẩm và lấy lại tiền với chi tiết như sau:',
      CHECKOUT:
        'Người mua đã thanh toán nhưng không nhận được sản phẩm đúng thời gian thỏa thuận;',
      ERROR: 'Sản phẩm bị lỗi hoặc bị hư hại trong quá trình vận chuyển;',
      WRONG: 'Giao sai sản phẩm cho Người mua (VD: loại, số lượng, v.vv...);',
      CLEAR:
        'Sản phẩm khách hàng nhận được khác biệt một cách rõ rệt so với thông tin trên website;',
      NO_CHANGE: '3. Không thay đổi ý định mua hàng',
      NO_ACCEPT:
        ' Trừ khi được đề cập trong Chính sách Trả hàng và Hoàn tiền này, các trường hợp trả hàng do khách hàng thay đổi ý định mua hàng sẽ không được chấp nhận.',
      CONDITION_BACK: '4. Điều kiện đổi trả',
      PRODUCT_TYPE:
        'Để hạn chế các rắc rối phát sinh liên quan đến trả hàng, Khách hàng lưu ý cần phải gửi trả sản phẩm bao gồm toàn bộ phụ kiện đi kèm nếu có, và sản phẩm phải trong tình trạng nguyên vẹn như khi nhận hàng.',
      FEE: '5. Chi phí trả hàng',
      RETURN:
        'Tùy vào từng trường hợp mà chúng tôi sẻ thống nhất với khách hàng về việc ai sẽ là người chịu phí vận chuyển đối với hàng trả lại.',
      NOTICE: '6. Hoàn tiền cho Hàng trả lại',
      USER: 'Chúng tôi chỉ hoàn tiền cho Người mua khi đã nhận được Hàng trả lại.',
      LOOK: 'Lưu ý:',
      BANK: 'Với đơn hàng COD, Người mua có trách nhiệm phải cung cấp thông tin tài khoản ngân hàng để nhận tiền hoàn trả.',
    },
    SHIP_PAGE: {
      TITLE: 'CHÍNH SÁCH GIAO HÀNG',
      COMMIT:
        'CÔNG TY TNHH CÔNG TY TNHH SOPHIE HEALTHCARE VIỆT NAM sẽ giao hàng trong khoảng thời gian cam kết khi khách hàng đặt hàng.',
      SAVE: 'Ship tiết kiệm: ( hàng khô, 1-2 ngày sẽ nhận được hàng)',
      FEE: 'Phí ship 22.000 VNĐ/Đơn (Q1,2,3,4,5,6,7,8,9,10,11,12, Tân Bình, Bình Thạnh, Phú Nhuận, Gò Vấp, BìnhTân, Thủ Đức).',
      FEE2: 'Phí ship 35.000 VNĐ/Đơn ( Củ Chi, Hóc Môn, Nhà Bè, Bình Chánh)',
      FEE3: 'Phí ship đi tỉnh ( 2-3 ngày sẽ nhận đc hàng) : giao động từ 30.000 – 50.000 VNĐ/ đơn',
      NORMAL_SHIP:
        'Ship thường: ( hàng lạnh, ship trong ngày) giá ship tính theo km',
      TYPE_ONE: 'dưới 7km: 20.000 VNĐ',
      TYPE_TWO: '7-12km : 30.000 VNĐ',
      TYPE_THREE: '12-18km: 40.000 VNĐ',
      TYPE_FOURTH: 'Trên 18km: 50.000 VNĐ',
      BILL: 'Hóa đơn trên 1.000.000 Sophie Healthcare sẽ hỗ trợ ship 20.000 VNĐ',
      DESC: 'Trường hợp địa điểm giao hàng không thuộc các khoản trên, chúng tôi sẻ thỏa thuận với quý khách bằng email hoặc điện thoại. Nếu phát sinh chậm trễ trong việc giao hàng chúng tôi sẻ thông báo cho quý khách và việc có tiếp tục đơn hàng không tùy thuộc vào người mua.',
      NEXT_POST: 'Bài viết khác',
      NOW: 'GIAO HÀNG TẬN NƠI',
    },
  },
  SERVICE: {
    BABY_BATH: {
      TITLE: 'Dịch vụ <span>tắm bé</span>',
      HOME: 'Tắm bé sơ sinh tại nhà.',
      DESC: 'Bạn mới lần đầu làm cha, vợ bạn mới sinh xong cần được nghỉ ngơi để hồi phục. Hãy thông qua Sophie để tìm một người chăm sóc bé yêu của bạn đúng cách. Điều dưỡng của Sophie có chứng chỉ tắm bé và sẽ giúp bạn phát hiện sớm các bệnh của trẻ sơ sinh nếu có. Hãy để chúng tôi giúp bạn chăm sóc những em bé đáng yêu.',
      PROCESS: '<span>Quy trình</span> <span>đặt dịch vụ tắm bé</span>',
      SUB_PROCESS:
        'Đặt dịch vụ tắm bé tại nhà nhanh chóng, đơn giản và thuận tiện chỉ với 4 bước trên ứng dụng Sophie.',
      WANT: 'Đặt lịch tắm bé bạn muốn',
      MEMBER: 'KTV, chuyên viên đến tắm bé theo lịch',
    },
    SICK: {
      TITLE: '<span>Dịch vụ</span> chăm sóc người ốm',
      HOME: 'Điều dưỡng, chăm sóc tại nhà.',
      DESC: 'Gia đình bạn không may có người ốm mà bạn còn phải đi làm để tạo ra nguồn thu nhập cho gia đình. Có thể bạn chưa biết cách chăm sóc người thân của mình khi bị ốm. Hãy để Sophie kết nối bạn với những điều dưỡng tận tâm, sẽ giúp bạn bớt lo âu và người thân mau hồi phục.',
      CONNECT: 'Kết nối với các điều dưỡng hàng đầu',
      PROCESS: '<span>Quy trình</span> đặt dịch vụ chăm sóc người ốm',
      SUB: 'Đặt dịch vụ chăm sóc người ốm tại nhà nhanh chóng, đơn giản và thuận tiện chỉ với 4 bước trên ứng dụng Sophie.',
      FIND: 'Tìm kiếm điều dưỡng phù hợp',
      STEP: 'Đặt lịch chăm sóc người ốm',
      CARE: 'KTV, chuyên viên đến chắm sóc theo lịch',
    },
    BLOOD: {
      TITLE: '<span>Dịch vụ</span> lấy máu tại nhà',
      AT_HOME: 'Lấy mẫu xét nghiệm tại nhà.',
      DESC: 'Bạn muốn kiểm tra các chỉ số sức khoẻ của mình thông qua việc xét nghiệm. Bạn ngại đến bệnh viện hay phòng khám để chờ đợi. Hãy dùng Sophie để kết nối dịch vụ lấy máu tại nhà của các phòng xét nghiệm liên kết. Nhân viên sẽ tới lấy mẫu tại nhà và trả kết quả thông qua ứng dụng, Zalo và email.',
      CONNECT: 'Kết nối với các phòng xét nghiệm hàng đầu',
      PROCESS: '<span>Quy trình</span> đặt dịch vụ lấy máu tại nhà',
      SUB: 'Đặt dịch vụ lấy máu tại nhà nhanh chóng, đơn giản và thuận tiện chỉ với 4 bước trên ứng dụng Sophie.',
      FIND: 'Tìm kiếm phòng xét nghiệm',
      SERVICE: 'Đặt lịch lấy máu tại nhà',
      SERVICE_HOME: 'KTV, chuyên viên đến lấy máu theo lịch',
    },
    PHYSICAL: {
      TITLE: '<span>Dịch vụ</span> vật lý trị liệu',
      AT_HOME: 'Bác sĩ, điều dưỡng chuyên môn chăm sóc, điều trị tại nhà.',
      DESC: 'Bạn hoặc người thân không may gặp phải vấn đề vận động do chấn thương hay do các bệnh khác. Bạn không biết cách tập như thế nào để mau hồi phục. Hãy dùng Sophie tìm kiếm một kỹ thuật viên có kinh nghiệm để tập các bài tập giúp hồi phục sức khoẻ nhanh nhất với chi phí hợp lý.',
      CONNECT: 'Kết nối với các chuyên viên hàng đầu',
      PROCESS: '<span>Quy trình</span> đặt dịch vụ vật lý trị liệu tại nhà',
      SUB: 'Đặt dịch vụ vật lý trị liệu tại nhà nhanh chóng, đơn giản và thuận tiện chỉ với 4 bước trên ứng dụng Sophie.',
      FIND: 'Tìm kiếm phòng khám',
      SERVICE: 'Đặt lịch vật lý trị liệu tại nhà',
      SERVICE_HOME: 'KTV, chuyên viên đến trị liệu theo lịch',
    },
  },
  BLOG: {
    SEE_MORE: 'Xem tất cả',
    LOCATION: 'Blog sức khoẻ',
    SEARCH_TEXT: 'TÌM KIẾM',
    LIST_CATEGORY_TITLE: 'DANH MỤC BLOG',
    SEARCH_PLACEHOLDER: 'Nhập từ khoá tìm kiếm',
    LIST_TOP_NEWS_TITLE: 'BÀI VIẾT NỔI BẬT',
    SEARCH_LOCATION: 'Kết quả tìm kiếm',
    NOT_FOUND_TITLE: 'Không tìm thấy kết quả phù hợp',
    NOT_FOUND_SUB_TITLE: 'Vui lòng thử với từ khoá tìm kiếm khác.',
  },
  updateinfouserpage: {
    middlename: 'Vui lòng nhập họ và tên đệm',
    username: 'Vui lòng nhập tên',
  },
  FOOTER: {
    ABOUT_SOPHIE: 'Giới thiệu về Sophie',
    HUMAN_AND_RECRUITER: 'Nhân sự & tuyển dụng',
    EXECUTIVE_BOARD: 'Ban điều hành',
    CONTACT: 'Liên hệ',
    COMPANY: 'CTY TNHH SOPHIE HEALTHCARE VIỆT NAM',
    CODE: 'Mã số thuế/mã DN:',
    PHONE: '0317352307',
    COPY_RIGHT: '© 2022 Sophie Healthcare. All rights reserved',
    ABOUT: 'Về Sophie',
    SUPPORT: 'Thông tin hỗ trợ',
    PAY_BACK: 'Trả hàng và hoàn tiền',
    RULE: 'Chính sách giao hàng',
    PRIVATE: 'Bảo mật thông tin cá nhân',
    GENERAL: 'Chính sách chung',
    FOR_DOCTOR: 'Dành cho bác sĩ, nhà thuốc',
    FOR_AFFILIATE: 'Dành cho cộng tác viên',
    SUPPORT_CALL: 'Hỗ trợ, tư vấn (8h00-22h00)',
    PHONE_RE: '1900 8239',
    CONNECT: 'Kết nối với Sophie',
  },
}

export default vi
