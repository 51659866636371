import CryptoJS from 'crypto-js'
import moment from 'moment'
import 'moment/locale/vi'
import queryString from 'query-string'
import { appConfig } from '../config'
import { decryptData, IDecryptDataOptions } from 'src/api/config/encode'
import { t } from 'i18next'

export function paginateArray<T>(array: T[], page: number, limit: number): T[] {
  return array.slice((page - 1) * limit, page * limit) as T[]
}

export const createRedirectUrl = (): string => {
  const { pathname, search } = window.location

  if (pathname === '/' && !search) return ''

  return queryString.stringify({
    redirectUrl: `${pathname}${search}`,
  })
}

export const getTimeNews = (dateTime: string, format?: string) => {
  // return moment(dateTime).locale('vi').format('ll')
  return moment(dateTime)
    .locale('vi')
    .format(format ?? 'DD [Th]M, YYYY')
}

export const generateSignatureUser = (jsonBody: any, timestamp: Number) => {
  const encodedWord = CryptoJS.enc.Utf8.parse(JSON.stringify(jsonBody))
  const dataBody = CryptoJS.enc.Base64.stringify(encodedWord)
  const dataTemp = 'v0:' + timestamp + ':' + dataBody

  let signature = CryptoJS.HmacSHA256(
    dataTemp,
    appConfig.CLIENT_SECRET
  ).toString(CryptoJS.enc.Hex)
  signature = 'v0=' + signature

  return [signature]
}

export const generateSignaturePartner = (jsonBody: any, timestamp: Number) => {
  const encodedWord = CryptoJS.enc.Utf8.parse(JSON.stringify(jsonBody))
  const dataBody = CryptoJS.enc.Base64.stringify(encodedWord)
  const dataTemp = 'v0:' + timestamp + ':' + dataBody

  let signature = CryptoJS.HmacSHA256(
    dataTemp,
    appConfig.PARTNER_CLIENT_SECRET
  ).toString(CryptoJS.enc.Hex)
  signature = 'v0=' + signature

  return [signature]
}

/**
 * Decrypt data response from api
 * @param {*} response : String
 * @param {*} url : String
 * @returns
 */

export function decryptDataResponse(
  response: any,
  customOptions: IDecryptDataOptions = {}
) {
  try {
    if (response === undefined) return ''
    if (response.data !== undefined) {
      var objectJson = decryptData(response.data, customOptions)
    } else {
      objectJson = decryptData(response, customOptions)
    }
    // if (objectJson === undefined) throw 'SERVER_CHANGE_KEY'
    return objectJson
  } catch (ex) {
    // throw 'SERVER_CHANGE_KEY'
  }
}

export function checkIfHandshakeEmptyOrExpired(token: string) {
  var check = false
  try {
    if (token === undefined || token === null || token === '') {
      check = true
    } else {
      check = false
    }
  } catch (ex) {
    // throw 'Error checkIfHandshakeEmptyOrExpired' + ex
  }
  return check
}

export function calculateDeliveryTime(time: string) {
  var deliveryTime = ''
  try {
    var totalHours = 0
    if (time.includes(' giờ')) {
      totalHours = Number.parseInt(time.replace(' giờ', ''))
    } else {
      totalHours = Number.parseInt(time)
    }
    const workDays = Math.ceil(totalHours / 8)
    if (workDays > 0) {
      const currentDate = moment()
      const endDate = moment(currentDate).add(workDays, 'days')
      deliveryTime =
        t('cart.deliveryTime') +
        ' ' +
        currentDate.locale('vi').format('DD [Th]M') +
        ' - ' +
        endDate.locale('vi').format('DD [Th]M')
    }
  } catch (ex) {
    return deliveryTime
  }
  return deliveryTime
}

// get current url
export function getCurrentUrl(url: string) {
  if (url.includes('https://') || url.includes('http://')) {
    return url
  }
  const domain = window.location.origin
  return `${domain}${url}`
}
