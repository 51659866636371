// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import {
  Switch,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
} from '@mui/material'
import { SyntheticEvent } from 'react'

// ----------------------------------------------------------------------

interface Props extends Omit<FormControlLabelProps, 'control'> {
  name: string
  helperText?: React.ReactNode
  onChange?: (event: SyntheticEvent<Element, Event>, checked: boolean) => void
}

export default function RHFSwitch({
  name,
  helperText,
  onChange,
  ...other
}: Props) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <FormControlLabel
            control={
              <Switch
                {...field}
                checked={field.value}
                onChange={event => {
                  field.onChange(event.target.checked)
                  if (onChange) {
                    onChange(event, event.target.checked)
                  }
                }}
              />
            }
            {...other}
          />

          {(!!error || helperText) && (
            <FormHelperText error={!!error}>
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </div>
      )}
    />
  )
}
