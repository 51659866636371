import { Navigate } from 'react-router-dom'
// routes
import { PATH_ADMIN, PATH_AFFILIATE, PATH_ANALYSIS } from '../routes/paths'
// components
import LoadingScreen from '../components/loading-screen'
//
import { useAuthContext } from './useAuthContext'
import { Role } from 'src/constants/enum'

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: React.ReactNode
}

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, isInitialized, role } = useAuthContext()

  if (isAuthenticated) {
    switch (role) {
      case Role.Admin:
        return <Navigate to={PATH_ADMIN.general.approve} />
      case Role.Pharmacy:
        return <Navigate to={PATH_ADMIN.general.order} />
      case Role.Analysis:
        return <Navigate to={PATH_ANALYSIS.root} />
      case Role.Affiliate:
        return <Navigate to={PATH_AFFILIATE.product} />

      default:
        break
    }
  }

  if (!isInitialized) {
    return <LoadingScreen />
  }

  return <> {children} </>
}
