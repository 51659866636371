// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`
}

const ROOTS_AUTH = '/auth'
const ROOTS_ADMIN = '/admin'
const ROOTS_ANALYSIS = '/analysis'
const ROOTS_AFFILIATE = '/affiliate'

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
}

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  ComingSoonPage: '/coming-soon',
  components: '/components',
}

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  general: {
    dashboard: path(ROOTS_ADMIN, '/coming-soon'),
    comingSoon: path(ROOTS_ADMIN, '/coming-soon'),
    approve: path(ROOTS_ADMIN, '/approve'),
    order: path(ROOTS_ADMIN, '/order'),
    pharmacy: path(ROOTS_ADMIN, '/pharmacy'),
    shop: path(ROOTS_ADMIN, '/pharmacy/shop'),
    product: path(ROOTS_ADMIN, '/pharmacy/product'),
    promotion: path(ROOTS_ADMIN, '/promotion'),
    discountPromotion: path(ROOTS_ADMIN, '/promotion/discount'),
    transportPromotion: path(ROOTS_ADMIN, '/promotion/transport'),
    logout: path(ROOTS_ADMIN, '/logout'),
  },
  user: {
    profile: path(ROOTS_ADMIN, '/user/profile'),
    account: path(ROOTS_ADMIN, '/user/account'),
  },
}

export const PATH_ANALYSIS = {
  root: ROOTS_ANALYSIS,
  app: path(ROOTS_ANALYSIS, '/app'),
  service: path(ROOTS_ANALYSIS, '/service'),
  request: path(ROOTS_ANALYSIS, '/request'),
}

export const PATH_AFFILIATE = {
  root: ROOTS_AFFILIATE,
  statistic: path(ROOTS_AFFILIATE, '/statistic'),
  product: path(ROOTS_AFFILIATE, '/product'),
  historySeo: path(ROOTS_AFFILIATE, '/history-seo'),
  withdrawRequest: path(ROOTS_AFFILIATE, '/withdraw-request'),
}

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
}

export const PATH_ZONE_ON_STORE =
  'https://mui.com/store/items/zone-landing-page/'

export const PATH_MINIMAL_ON_STORE =
  'https://mui.com/store/items/minimal-dashboard/'

export const PATH_FREE_VERSION =
  'https://mui.com/store/items/minimal-dashboard-free/'

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1'
