export enum ApproveStatus {
  Pending = 'Pending',
  Actived = 'Actived',
  InActived = 'InActived',
}

export enum Role {
  Doctor = 'Doctor',
  Hospital = 'Hospital',
  Pharmacist = 'Pharmacist',
  Pharmacy = 'Pharmacy',
  Nursing = 'Nursing',
  Analysis = 'Analysis',
  Affiliate = 'Affiliate',
  Admin = 'Admin',
}
